@import "@STYLES/mixins";

.menu-list-multi {
    @include custom-scrollbar;
}

.all-checkbox {
    padding: 12px 16px;

    font-size: 12px;

    cursor: pointer;

    & * {
        color: #a7b5be;
    }

    &.selected *,
    &:hover * {
        color: #202020;
    }
}
