.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  background-color: #4eb0c2;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;

    .title {
      margin-bottom: 76px;

      color: white;
      font-weight: 700;
      font-size: 40px;
      line-height: 54px;
      white-space: pre-line;
      text-align: center;
    }

    .logo {
      margin-left: 4px;
      overflow: hidden;
    }
  }
}
