@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.preview-chapter-modal {
  $modal-header-height: 60px;
  height: 100%;

  overflow: hidden;

  border-radius: $main-border-radius;

  .header-wrapper {
    position: sticky;
    top: 0;
    z-index: 1;

    height: $modal-header-height;
    padding: 0 20px;

    background-color: color(app-white);
    border-bottom: 1px solid color(marriott-light-grey-2);
    box-shadow: $main-box-shadow;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - #{$modal-header-height});

    background-color: color(app-bg);
  }
}
