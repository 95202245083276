.variant-form {
    //

    .is-default-variant {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        .checkbox-title {
            margin-right: 15px;

            font-weight: 700;
            font-size: 14px;
        }
    }

    .selects {
        margin-bottom: 40px;

        :global .new-variant {
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin-bottom: 20px;

            span {
                font-weight: 700;
                font-size: 12px;
            }

            input {
                outline: none;
                height: 36px;
                padding: 0 16px;
                font-size: 12px;
                &::placeholder {
                    color: color(marriott-light-grey);
                    font-size: 14px;
                }
            }

            .Mui-focused,
            &:hover {
                fieldset {
                    border: 1px solid #a7b5be;
                }
            }

            .MuiFormHelperText-root {
                margin-left: 0;
            }
        }

        .select-wrapper:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}
