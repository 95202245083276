@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.select-content-sidebar {
  position: relative;
  width: 100%;
  padding: 25px;
  background-color: color(app-white);
  .sidebar-title {
    margin-bottom: 25px;
    color: color(marriott-primary);
    font-weight: 700;
    font-size: 16px;
  }

  .sections-search-wrapper {
    margin-bottom: 20px;
  }

  .delimiter {
    width: 100%;
    height: 1px;
    margin: 25px 0;

    background-color: color(marriott-light-grey-2);
  }

  .filter-sections {
    //

    .tags {
      margin-bottom: 15px;
      .tags-title {
        margin-bottom: 15px;
        font-weight: 700;
        font-size: 14px;
      }

      .tags-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
      }

      .tags-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -10px;

        @include custom-scrollbar;

        .tags-list-item {
          display: flex;
          width: calc(50% - 10px);
          margin-right: 10px;
          margin-bottom: 10px;
        }

        .tags-not-found {
          margin: 10px 0;

          font-size: 12px;
        }
      }
    }

    .other-filters {
      //

      .other-filters-subtitle {
        margin-bottom: 15px;

        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}
