.section-results {
  $gap: 50px;

  padding-bottom: $gap;

  .section-base-text {
    padding-top: $gap;
  }

  .variants-list {
    padding-top: $gap;

    .variant {
      &:not(:last-child) {
        padding-bottom: $gap;
      }
    }
  }
}
