@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.step-breadcrumbs {
  width: 100%;
  height: $step-breadcrumbs-height;

  background-color: color(app-white);
  border-bottom: 1px solid color(marriott-light-grey-2);
  box-shadow: $main-box-shadow;

  .content-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
