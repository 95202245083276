@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

// TODO fix ellipsis
.hidden-title {
  $height: 20px;

  position: sticky;
  top: $subheader-height + $admin-breadcrumbs-height + ($height / 2) + 1px;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: $height;
  margin-bottom: -$height;
  padding-right: 305px;
  padding-left: 220px;
  overflow: hidden;

  color: color(marriott-primary);
  font-weight: 700;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;

  pointer-events: none;
}

.section {
  position: relative;

  width: 100%;

  background-color: color(app-white);
}
