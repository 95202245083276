@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.subheader {
  position: relative;
  z-index: 1;
  width: 100%;
  height: $subheader-height;
  background-color: color(marriott-dark-blue);

  .content-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    .segment {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      font-weight: 400;
      color: color(app-white);
      font-size: 12px;
      white-space: nowrap;
      line-height: 1;

      &:not(:last-child) {
        $mr: 30px;
        $w: 1px;
        margin-right: $mr;

        &::after {
          position: absolute;
          right: -$mr / 2 - ($w / 2);
          z-index: 1;
          width: $w;
          height: 50%;
          background-color: color(app-white);
          transform: rotate(20deg);
          content: "";
        }
      }
    }
  }
}
