.chapter-results {
  $gap: 50px;

  .chapter-introduction-text {
    padding-top: $gap;
    padding-bottom: $gap;
  }

  .sections-list {
    //

    .section {
      padding-bottom: $gap;

      .section-base-text {
        padding-top: $gap;
      }

      .variants-list {
        padding-top: $gap;

        .variant {
          &:not(:last-child) {
            padding-bottom: $gap;
          }
        }
      }
    }
  }
}
