@import "@STYLES/colors.scss";

.section-sticky-header {
  $sidebar-width: 330px;
  $sidebar-width-less: 250px;
  position: sticky;
  z-index: 5;

  display: flex;
  align-items: center;
  margin-bottom: -2px;
  margin-left: $sidebar-width;

  border-top: 1px solid color(marriott-light-grey-2);
  border-bottom: 1px solid color(marriott-light-grey-2);

  @media (max-width: 1200px) {
    margin-left: $sidebar-width-less;
  }

  .header-wrapper {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 34px;
    padding: 0 30px;

    background-color: color(app-white);

    .title-prefix {
      position: absolute;
      left: 30px;

      margin-right: 15px;

      color: color(marriott-light-grey);
      font-weight: 500;
      font-size: 14px;
    }

    .title {
      color: color(marriott-orange);
      font-weight: 700;
      font-size: 14px;
    }
  }
}
