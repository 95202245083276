@import "@STYLES/colors.scss";

.filters-view {
    padding: 0 24px;
  .sidebar-title {
    margin-top: 15px;
    margin-bottom: 25px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 16px;
  }

  .delimiter {
    width: 100%;
    height: 1px;
    margin: 25px 0;

    background-color: color(marriott-light-grey-2);
  }

  .input-wrapper {
    margin-bottom: 30px;
  }

  .properties {
    .select-wrapper:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .drafts-checkbox-wrapper {
    //
  }

  .tags {
    $gap: 10px;

    .tags-title {
      margin-bottom: 15px;

      font-weight: 700;
      font-size: 14px;
    }

    .tags-list {
      display: flex;
      flex-wrap: wrap;
      margin-right: -$gap;
      margin-bottom: -$gap;

      .tag-item {
        width: calc(50% - #{$gap});
        margin-right: $gap;
        margin-bottom: $gap;
      }
    }
  }
}
