@import "@STYLES/colors.scss";

.permissions {
  //

  .permissions-title {
    margin-bottom: 30px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 14px;
  }

  .is-admin {
    margin-bottom: 30px;
  }

  .permissions-list {
    //

    .permission-wrapper {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
