@import "@STYLES/colors";

.checkbox-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;

  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  .unchecked-view {
    width: 8px;
    height: 2px;

    background-color: white;
  }

  &.checked {
    background-color: #4eb0c2;
    border-color: #4eb0c2;
    box-shadow: 0 0 6px 0 #4eb0c29c;
  }

  &.half-checked {
    background-color: rgb(78 176 194 / 50%);
    box-shadow: 0 0 6px 0 #4eb0c29c;
  }

  &.unchecked {
    border-color: #adb4bd;
  }

  &.disabled {
    background-color: gainsboro;
    border-color: gainsboro;
    box-shadow: none;
  }
}
