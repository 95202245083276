@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.document-tags {
    background-color: color(app-white);
    padding: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-top: 1px solid #F0F0F0;
    .tag-warpper {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 1;
        min-width: 604px;
        :global {
            .MuiTypography-root {
                min-width: 110px;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                white-space: pre;
            }
        }
    }
}

.tagsWrap {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 7px 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    min-height: 46px;
    position: relative;
    :global {
        .lock-wrapper {
            position: absolute;
            top: -14px;
            right: -14px;
            z-index: 1;
        }
        .MuiFormControl-root {
            .MuiInputBase-root {
                padding: 4px 39px 4px 4px;
                height: 30px;
            }
        }
    }

    .tags-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
}
