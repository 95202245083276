:global .subsection-button-wrapper {
    padding: 10px 0;
    border-top: 1px solid var(--marriott-light-grey-2);
    .clear-button {
        background: #edf9ff;
        color: #1890ff;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        border: 1px solid #1890ff;
        height: 34px;
        width: 100%;
        gap: 10px;
        min-height: auto;
        &:hover {
            svg {
                path {
                    fill: var(--app-green);
                }
            }
        }
        :global {
            .custom-btn-title {
                font-weight: 400;
                margin: 0;
                line-height: 20px;
            }
        }
    }
}
