@import "@STYLES/colors.scss";

.loading-loader {
  margin: 50px 0;
}

.error {
  width: 100%;
  padding: 30px 0;

  color: color(marriott-orange);
  font-weight: 700;
  font-size: 14px;
  text-align: center;
}

.data-loader {
  position: relative;

  width: 100%;
  height: 100%;

  .fetching-loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 7;

    width: 100%;
    height: 100%;

    background-color: rgb(255 255 255 / 70%);

    .loader {
      position: sticky;
      top: 180px;
    }
  }
}
