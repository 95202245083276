@import "@STYLES/colors.scss";
@import "@STYLES/mixins.scss";

.icon-square-button {
  position: relative;

  width: 100%;
  height: 100%;

  background-color: color(marriott-primary);
  border: 2px solid transparent;
  border-radius: 5px;

  @include hover-highlight(0.1);

  .lock-wrapper {
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 1;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;

    .icon {
      display: flex;
      justify-content: flex-end;
    }

    .title {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: flex-end;

      color: color(app-white);
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-align: left;
    }
  }

  &.locked {
    background-color: color(app-white);
    border: 2px solid color(marriott-light-grey);

    pointer-events: none;

    .content {
      .title {
        color: color(marriott-light-grey);
      }

      svg {
        fill: color(marriott-light-grey);
      }

      .icon.logs svg {
        fill: none;

        stroke: color(marriott-light-grey);
      }
    }
  }
}
