@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.basic-textarea {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    padding-bottom: 6px;

    font-weight: 700;
    font-size: 12px;
  }

  textarea {
    width: 100%;
    min-height: 100px;
    max-height: 300px;
    padding: 10px;

    font-size: 12px;

    border: 1px solid color(marriott-light-grey);
    border-radius: $main-border-radius;

    resize: vertical;

    @include custom-scrollbar;

    &::placeholder {
      color: color(marriott-light-grey);
    }
  }

  &.validatable {
    $pb: 20px;

    padding-bottom: $pb;

    &.error {
      //

      textarea {
        border: 1px solid color(marriott-orange);
      }

      .error-message {
        position: absolute;
        top: calc(100% - #{$pb});

        display: flex;
        align-items: flex-end;
        width: 100%;
        height: $pb;

        color: color(marriott-orange);
        font-size: 12px;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .errors-list {
      display: none;
    }
  }
}
