@import "@STYLES/colors.scss";

.grid-view {
  $gap: 35px;
  $pseudo-padding: 10px;

  display: flex;
  flex-wrap: wrap;

  margin-top: $gap;
  margin-right: -$gap;
  margin-bottom: -$gap;

  .grid-item {
    position: relative;

    display: flex;
    flex-direction: column;
    width: calc(33.33% - #{$gap});
    height: 100%;
    margin-right: $gap;
    margin-bottom: $gap;

    @media (max-width: 1600px) {
      width: calc(50% - #{$gap});
    }

    @media (max-width: 1300px) {
      width: calc(100% - #{$gap});
      min-width: 300px;
    }

    &.has-variants.checked::before {
      position: absolute;
      top: -$pseudo-padding;
      left: -$pseudo-padding;
      z-index: -1;

      width: calc(100% + #{$pseudo-padding * 2});
      height: calc(100% + #{$pseudo-padding * 2});

      background-color: color(app-white);
      border: 1px solid color(marriott-light-grey);
      border-radius: 7px;

      content: "";
      pointer-events: none;
    }

    .section-wrapper {
      //
    }

    .variants {
      margin-top: 20px;

      .variants-title {
        margin-bottom: 20px;
        margin-left: 10px;

        font-weight: 700;
        font-size: 14px;
      }

      .variants-list {
        //

        .variant {
          //

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
