@import "@STYLES/colors.scss";

.lock {
  $color: color(marriott-light-grey);
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: color(app-white);
  border: 2px solid $color;

  border-radius: 50%;

  &.small {
    border: 1px solid $color;
  }

  &.medium {
    border: 1px solid $color;
  }

  &.free {
    border-color: transparent;
  }

  svg {
    transform: translateY(-1px);

    fill: $color;
  }
}
