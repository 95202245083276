@import "@STYLES/colors.scss";

.user-info {
  //

  .user-info-title {
    margin-bottom: 30px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 14px;
  }

  .data {
    //

    .data-item {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .data-title {
        margin-right: 15px;

        color: color(marriott-light-grey);
        font-weight: 700;
        font-size: 12px;
      }

      .data-value {
        font-size: 12px;
      }
    }
  }
}
