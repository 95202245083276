@import "@STYLES/colors.scss";

.section-sticky-header {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 34px;
  padding: 0 30px;

  background-color: color(app-white);

  .title-prefix {
    position: absolute;
    left: 30px;

    margin-right: 15px;

    color: color(marriott-light-grey);
    font-weight: 500;
    font-size: 14px;
  }

  .title {
    color: color(marriott-orange);
    font-weight: 700;
    font-size: 14px;
  }
}
