@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.introduction-text-editor {
  position: absolute;

  visibility: hidden;

  &.show {
    position: relative;

    visibility: visible;
  }
}

.editor-loading {
  display: flex;
  flex: 1;
  height: 700px;
  padding: 100px 0;
}
