.page {
    // background-color: #fff;
    flex: 1;
    display: grid;
    :global {
        .max-constraint {
            display: flex;
            flex-direction: column;
            flex: 1;
        }
    }
    h1 {
        margin: 0 0 30px;
    }
}
