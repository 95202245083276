@import "@STYLES/colors.scss";

.warning {
  display: flex;
  align-items: center;

  .icon {
    margin-right: 8px;
  }

  .text {
    color: color(app-yellow);
    font-size: 12px;
    line-height: 130%;
  }
}
