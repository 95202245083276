@import "@STYLES/colors.scss";

.chapter-sticky-header {
  display: flex;
  align-items: center;
  min-width: 100%;
  height: 40px;

  background-color: color(app-white);

  .chapter-title {
    margin-right: 10px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 18px;
  }

  .selectors {
    display: flex;
    align-items: baseline;

    white-space: nowrap;
  }
}
