@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.variant {
  //

  .variant-item-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .variant-item-title {
      display: flex;
      width: 100%;

      .title {
        overflow: hidden;

        color: color(marriott-primary);
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .edit-icon {
        margin-right: 10px;

        transform: translateY(-2px);
      }
    }

    .is-default-title {
      color: color(marriott-orange);
      font-weight: 700;
      font-size: 14px;
    }
  }

  .variant-item-properties {
    //

    .property {
      display: flex;
      justify-content: space-between;

      font-size: 12px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .property-title {
        margin-right: 10px;

        color: color(marriott-light-grey);
        font-style: italic;
      }

      .property-value {
        text-align: right;
      }
    }
  }

  .variant-item-tags {
    $tag-gap: 15px;
    margin: 20px 0 0 0;

    .tags-title {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 14px;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .tag-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 4px 10px;
        font-size: 12px;
        line-height: 150%;
        text-align: center;
        background-color: color(app-bg);
        border: 1px solid color(marriott-light-grey-2);
        border-radius: $main-border-radius;
      }
    }
  }
}
