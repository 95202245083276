@import "@STYLES/colors.scss";

.content {
  padding: 50px 0;

  .page-name {
    margin-bottom: 50px;

    font-weight: 700;
    font-size: 24px;
  }

  .add-vendor-button {
    width: 100%;
    height: 60px;
    margin: 50px 0;
  }

  .vendors-list {
    $gap: 50px;

    display: flex;
    flex-wrap: wrap;
    margin-right: -$gap;

    .vendor-wrapper {
      width: calc(calc(100% / 3) - #{$gap});
      margin-right: $gap;

      @media (max-width: 1600px) {
        width: calc(calc(100% / 2) - #{$gap});
      }

      @media (max-width: 1200px) {
        width: calc(100% - #{$gap});
      }

      &:not(:last-child) {
        margin-bottom: 60px;
      }

      &.collapsed:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .vendors-not-found {
    margin: 20px 0;

    color: color(marriott-light-grey);
  }
}
