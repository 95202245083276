$pdf-editor-margin: 20px;

$pdf-page-height: (842px * 1.3333) - ((32mm - $pdf-editor-margin ) + (24mm - $pdf-editor-margin)) - 60px;
$pdf-page-width: (595px * 1.3333) - ((24mm - $pdf-editor-margin) * 2);

$pdf: (
  pdf-editor-margin: $pdf-editor-margin,
  pdf-page-height: $pdf-page-height,
  pdf-page-width: $pdf-page-width,
);
