.page {
    // background: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
    // padding-top: 50px;
    .brand-header {
        display: flex;
        align-items: center;
        gap: 24px;
        margin: 0 0 16px 0;
        h1 {
            margin: 0;
        }
        .brand-search {
            width: 25%;
            z-index: 0;

            :global {
                // .icon-wapper {
                //     right: 18px;
                //     left: unset;
                // }
                .input-wapper {
                    border:1px solid #D9D9D9;
                    border-radius: 6px;
                    align-items: center;
                }
            }

            input {
                // padding: 8px 45px 8px 8px;
                height: 38px;
            }
        }
    }

    .brand-data {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        grid-auto-rows: 180px;
        gap: 16px;
        margin-bottom: 10px;
        .brand-child {
            width: auto !important;
            margin: 0;
            aspect-ratio: unset !important;
            .title-wrap {
                font-size: 12px;
                line-height: normal;
            }
        }
    }
    .title-wrapper {
        font-size: 18px;
        font-weight: 500;
        line-height: 16.94px;
        letter-spacing: -0.02em;
        margin: 16px 0;
        color: #344054;
      
    }
}
