.page {
    // background: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
    // padding-top: 50px;
    .brand-header {
        display: flex;
        align-items: center;
        gap: 24px;
        position: relative;
        z-index: 1;
        h1 {
            margin: 0;
        }
        .brand-search {
            width: 25%;
            z-index: 0;

            :global {
                .location-search {
                    display: flex;
                    align-items: center;
                    border:1px solid#a7b5be;
                    border-radius: 5px;
                }
                .custom-container {
                    width: 100%;
                }
                .custom-control {
                   border: 0;
                }
                .input-wapper {
                    border: 1px solid #d9d9d9;
                    border-radius: 6px;
                    align-items: center;
                }
            }
            input {
                height: 38px;
            }
            :global {
                .custom-control {
                    min-height: auto;
                    height: auto;
                    line-height: normal;
                }
            }
        }
    }

    .brand-data {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        grid-auto-rows: 180px;
        gap: 16px;
        margin-bottom: 10px;
        .brand-child {
            width: auto !important;
            margin: 0;
            aspect-ratio: unset !important;
            .title-wrap {
                font-size: 12px;
                line-height: normal;
            }
        }
    }
}
