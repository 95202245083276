.value-container-multi {
    position: relative;

    padding: 0 0 0 10px;

    input[role="combobox"] {
        position: absolute;
    }

    .values {
        flex: 1;
        max-width: max-content;
        padding-right: 6px;
        overflow: hidden;

        line-height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;

        user-select: none;

        .placeholder {
            color: #adb4bd;

            user-select: none;
        }

        &.empty {
            padding-right: 0;
        }
    }

    .counter {
        position: absolute;
        right: 0;

        color: #adb4bd;
        line-height: 20px;

        user-select: none;
    }
}
