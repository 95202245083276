@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.sections {
    margin: 20px 0 20px 50px;
    border: 1px solid #e4e7ec;
    .sections-title-wrapper {
        display: flex;
        align-items: center;
        padding: 20px 50px;

        .sections-title {
            margin-right: 15px;

            font-weight: 700;
            font-size: 22px;
        }

        .grid-view {
            margin-right: 15px;

            .icon-button {
                padding: 10px;
            }
        }

        .list-view {
            .icon-button {
                padding: 10px 8px;
            }
        }

        .grid-view,
        .list-view {
            //

            .icon-button {
                background-color: transparent;
                border: 2px solid transparent;
                border-radius: 50%;

                svg {
                    // fill: color(marriott-dark-grey);
                }
            }

            &.active {
                .icon-button {
                    border: 2px solid color(marriott-light-grey);

                    svg {
                        // fill: color(app-white);
                    }
                }
            }
        }
    }

    .sections-lists-wrapper {
        $gap: 35px;
        .sections {
            margin: 0 0 20px 50px;
        }
        .grid-wrapper {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(422px, 1fr));
          padding: $gap 50px;
          gap: $gap;
            .grid-view {
                margin: 0;
                position: relative;
                display: flex;
                flex-direction: column;
                // width: calc(33.33% - #{$gap});
                height: 100%;
            }
        }

        .sections-list {
            margin-bottom: 50px;

            .chapter-title-wrapper {
                //
            }
        }
    }

    .sections-not-found,
    .chapter-has-body {
        margin-top: 40px;

        color: color(marriott-light-grey);
        font-size: 16px;
    }
}
