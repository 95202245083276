@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.variant-vendors {
    .variant-header {
        display: flex;
        align-items: center;
        .title {
            color: #262626;
            gap: 18px;
            min-width: 86px;
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
            text-align: left;
        }
    }

    .vendors-list {
        $gap: 25px;
        display: flex;
        flex-wrap: wrap;
        margin: 15px 0;

        .vendor-item-wrapper {
            width: calc(calc(100% / 3) - #{$gap});
            margin-right: $gap;
            margin-bottom: $gap;

            @media (max-width: 1600px) {
                width: calc(calc(100% / 2) - #{$gap});
            }

            @media (max-width: 1200px) {
                width: calc(100% - #{$gap});
            }
        }
    }

    .no-vendors {
        margin-bottom: 30px;

        color: color(marriott-light-grey);
        font-size: 14px;
    }

    .new-vendor-wrapper {
        //
    }
}
