:global {
    .custom-accordion-details,
    .MuiCollapse-wrapperInner {
        padding: 0;
        background: #f9fafb;
        ul {
            & .MuiButtonBase-root {
                background-color: #f9fafb;
                padding-right: 0;
            }
            & .MuiAccordionDetails-root {
                padding-right: 0 !important;
            }
        }
    }
}
