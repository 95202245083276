@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.sidebar-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;

  background-color: color(app-white);
  border-bottom: 1px solid color(marriott-light-grey-2);
  box-shadow: $main-box-shadow;

  label {
    display: flex;
    align-items: center;
    height: 100%;

    cursor: pointer;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 5px 15px 5px 0;

      color: color(marriott-light-grey);
      font-weight: 700;
      font-size: 12px;

      user-select: none;

      .filter-icon {
        margin-top: 2px;
        margin-right: 6px;

        fill: color(marriott-light-grey);
      }
    }

    &:not(:last-child) {
      margin-right: 15px;

      border-right: 1px solid color(marriott-light-grey-2);
    }

    input[type="radio"]:checked + .content {
      color: color(marriott-orange);

      .filter-icon {
        fill: color(marriott-orange);
      }
    }
  }
}
