@import "@STYLES/colors.scss";

.publish-all-button {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #ffc53d;
    padding: 5px 12px;
    height: 34px;
    gap: 10px;
    border-radius: 5px;

    .icon,
    .text {
        height: 100%;
        color: #fff;
    }

    .lock-wrapper {
        position: absolute;
        right: -12px;
        top: -11px;
    }

    .icon-wrapper {
        width: 15px;
        .icon {
            font-size: 28px;
            transform: rotate(-90deg) translateY(-2px);
            transform-origin: center center;
        }
    }

    .text {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
    }

    &:hover {
        .icon,
        .text {
            filter: brightness(1.1);
        }
    }

    &.locked {
        pointer-events: none;

        .text {
            color: color(marriott-light-grey);
        }

        &:disabled {
            .lock-wrapper {
                opacity: 0.4;
            }
        }
    }

    &:disabled {
        pointer-events: none;

        .icon,
        .text {
            color: color(marriott-light-grey-2);
        }
    }
}
