@import "@STYLES/colors.scss";

.rounded-checkbox {
  width: 50px;
  min-width: 50px;
  height: 24px;
  overflow: hidden;

  background-color: color(app-white);
  border: 1px solid color(marriott-light-grey-2);
  border-radius: 12px;

  label {
    position: relative;

    display: block;
    width: 100%;
    height: 100%;

    cursor: pointer;

    .circle {

      position: absolute;
      top: 50%;
      left: 4px;

      width: 18px;
      height: 18px;

      background-color: color(marriott-light-grey);
      border-radius: 50%;
      transform: translateY(-50%);

      transition: left 0.2s ease;
    }

    &.disabled {
      cursor: default;
      .circle {
        background-color: color(marriott-light-grey) !important;
      }
    }

    input[type="checkbox"]:checked + .circle {
      left: 28px;
    }
  }
}
