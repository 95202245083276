@import "@STYLES/mixins";

.basic-select {
    //

    label {
        display: flex;
        &.extra-padding{
            padding-bottom: 6px;
        }

        .title {
            font-weight: 700;
            font-size: 12px;
        }

        .loading-wrapper {
            margin-left: 8px;
        }
    }

    .menu-list {
        @include custom-scrollbar;
        .custom-option-icon {
            padding: 5px 10px;
            :global {
                .MuiButtonBase-root  {
                    svg {
                        height: 15px;
                        path {
                            fill: #716c6c;
                        }
                    }
                }
            }
        }
    }
}
