@import "@STYLES/colors.scss";

.vendor-header {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;

  .hidden-icon {
    $size: 34px;

    position: absolute;
    top: -$size / 2;
    left: -$size / 2;

    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;

    background-color: color(app-white);
    border: 2px solid color(marriott-light-grey);
    border-radius: 50%;
    box-shadow: 0 0 0 3px color(app-bg);

    pointer-events: none;
  }

  .title-wrapper {
    display: flex;
    align-items: center;

    .delete-button-wrapper {
      margin-right: 20px;
    }

    .title {
      color: color(app-white);
      font-weight: 700;
      font-size: 14px;
    }
  }

  .header-buttons {
    display: flex;
  }
}
