@import "@STYLES/colors.scss";

.generate-preview-pdf-button {
  $size: 32px;

  position: relative;

  width: $size;
  height: $size;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    svg {
      fill: color(marriott-light-grey);
    }

    .lock-wrapper {
      position: absolute;
      top: -10px;
      right: -9px;
      z-index: 1;
    }

    .processing {
      //
    }

    &.locked {
      pointer-events: none;

      svg {
        fill: color(marriott-light-grey);
      }
    }

    &.is-processing {
      pointer-events: none;
    }

    &:hover {
      svg {
        fill: color(marriott-primary);
      }
    }
  }
}
