.basic-icon-button {
  display: flex;
  align-items: center;

  button {
    //

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
