@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.base-text-sidebar {
  position: relative;
  z-index: 2;

  width: 300px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  background-color: color(app-white);
  border-left: 1px solid color(marriott-light-grey-2);
  box-shadow: $main-box-shadow;

  @media (max-width: 1500px) {
    width: 100%;

    border-left: none;

    .sidebar-wrapper {
      position: static !important;

      .button-wrapper {
        width: 50% !important;
      }
    }
  }

  @include custom-scrollbar($thumb-color: transparent, $track-color: rgba(249, 249, 249, 1));

  &:hover {
    @include custom-scrollbar($thumb-color: lightgrey, $track-color: rgba(249, 249, 249, 1));
  }

  .sidebar-wrapper {
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .version-control {
      width: 100%;
      padding: 25px;

      .title {
        margin-bottom: 30px;

        color: color(marriott-primary);
        font-weight: 700;
        font-size: 14px;
      }

      .versions {
        margin-bottom: 30px;
      }

      .included-to-version-control {
        margin-bottom: 30px;

        color: color(app-green);
        font-weight: bold;
        font-size: 12px;

        &.not-in-control {
          color: color(marriott-orange);
        }
      }

      .info {
        //

        .info-title {
          margin-bottom: 10px;

          font-weight: 700;
          font-size: 12px;
        }

        .info-data {
          color: color(marriott-light-grey);
          font-size: 12px;
          line-height: 150%;
        }

        .info-item {
          //

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }

      .no-versions {
        color: var(--marriott-light-grey);
        font-size: 14px;
      }
    }
  }

  .footer {
    position: sticky;
    bottom: -1px;

    padding: 15px 25px;

    background-color: color(app-white);
    border-top: 1px solid color(marriott-light-grey-2);

    .footer-top {
      margin-bottom: 15px;
    }
  }
}
