@import "@STYLES/colors.scss";

.page-header {
  background-color: color(app-white);
  position: relative;
.page-wrap {
  margin: 0px 40px;
  padding: 25px 15px 24px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .footer-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    :global {
      .MuiTypography-root {
        color: var(--marriott-dark-grey);
        font-size: 14px;
        font-weight: 400;
        min-width: 60px;
        line-height: 22px
      }
    }
    .footer-body {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;
    }
  }
}
  .section-options {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .options {
      display: flex;
      padding: 0 50px;
      .option {
        display: flex;
        align-items: center;
        height: 50px;
        padding-right: 30px;

        &:not(:last-child) {
          margin-right: 30px;

          border-right: 1px solid color(marriott-light-grey-2);
        }

        .checkbox-title {
          margin-right: 15px;

          color: color(marriott-light-grey);
          font-weight: 700;
          font-size: 14px;
        }
      }
    }

    .save-button-wrapper {
      margin: 0 50px;
    }
  }
}
