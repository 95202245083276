@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.basic-button {
  position: relative;

  width: 100%;
  padding: 0 15px;

  color: white;
  font-weight: 700;
  white-space: nowrap;

  border-radius: $main-border-radius;

  @include hover-highlight(0.1);

  &.locked {
    color: color(marriott-light-grey) !important;

    background-color: color(app-white) !important;
    border: 1px solid color(marriott-light-grey) !important;

    pointer-events: none;
  }

  &.is-processing {
    pointer-events: none;
  }

  .lock-wrapper {
    position: absolute;
    top: -14px;
    right: -14px;
    z-index: 1;
  }

  .processing {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
