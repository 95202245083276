@import "@STYLES/colors.scss";

.content {
  padding: 50px 0;

  .page-name {
    margin-bottom: 50px;

    font-weight: 700;
    font-size: 24px;
  }

  .users-list {
    //

    .user-wrapper {
      &:not(:last-child) {
        margin-bottom: 60px;
      }
    }
  }

  .users-not-found {
    margin: 20px 0;

    color: color(marriott-light-grey);
  }
}
