.page {
  // background-color: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: column;
  :global {
  .max-constraint {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
  h1 {
    margin: 0 0 30px;
  }
}
