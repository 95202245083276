@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.action-button-wrapper {
    &.active-button {
        button {
            background-color: rgb(29, 193, 124);
            color: white;
        }
        svg {
            path {
                fill: #fff;
            }
        }
    }
   :global {
    .MuiBadge-badge {
        border: 3px solid #ffffff;
        background-color: #40A9FF;
        height: 15px;
        width: 15px;
        border-radius: 15px;
    }
   }
    button {
        border-color: #d9d9d9;
        border-radius: 5px;
        color: #595959;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;

        :global {
            button {
                gap: 6px;
                .MuiButton-icon {
                    margin: 0;
                }
            }
        }
        svg {
            fill: #595959;
        }
    }
}
