.image-wrap{
    height: 90px;
    max-width: 100%;
    width: 100%;
    margin: 0 0 10px 0 ;
    img {
        height: 100%;
        width: 100%;
        filter: brightness(0) invert(1);
    }
}