@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";
@import "@STYLES/pdf.scss";

.modal-body {
  $sidebar-width: 330px;
  $sidebar-width-less: 250px;
  position: relative;

  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  .body {
    position: relative;

    width: 100%;
    height: 100%;

    .pseudo-sidebar {
      position: absolute;
      top: 0;
      bottom: 0;

      width: $sidebar-width;
      overflow-x: hidden;
      overflow-y: auto;

      background-color: color(app-white);
      border-right: 1px solid color(marriott-light-grey-2);
      box-shadow: $main-box-shadow;

      &.show-filters {
        z-index: 5;
      }

      .delimiter {
        width: 100%;
        height: 1px;
        margin: 25px 0;

        background-color: color(marriott-light-grey-2);
      }

      @include custom-scrollbar($thumb-color: transparent, $track-color: rgba(249, 249, 249, 1));

      &:hover {
        @include custom-scrollbar($thumb-color: lightgrey, $track-color: rgba(249, 249, 249, 1));
      }
    }
  }

  @media (max-width: 1200px) {
    .body {
      .pseudo-sidebar {
        width: $sidebar-width-less;
      }
    }
  }
}
