@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.mandatory-sidebar {
  position: relative;
  z-index: 2;

  width: 300px;
  padding: 25px;

  background-color: color(app-white);
  border-left: 1px solid color(marriott-light-grey-2);
  border-top-right-radius: $main-border-radius;
  border-bottom-right-radius: $main-border-radius;
  box-shadow: $main-box-shadow;

  @media (max-width: 1200px) {
    width: 100%;

    border-left: none;
    border-top-right-radius: 0;
    border-bottom-left-radius: $main-border-radius;
  }

  .always-mandatory-wrapper {
    margin-bottom: 50px;

    .checkbox-wrapper {
      display: flex;
      align-items: center;

      .checkbox-title {
        margin-right: 15px;

        font-weight: 700;
        font-size: 14px;
      }
    }

    .save-button-wrapper {
      margin-top: 30px;
    }
  }

  .conditions {
    transition: opacity 0.2s ease-in-out;

    .conditions-title {
      margin-bottom: 25px;

      color: color(marriott-primary);
      font-weight: 700;
      font-size: 14px;

      transition: color 0.2s ease-in-out;
    }

    .selects {
      margin-bottom: 25px;

      .select-wrapper {
        //

        .plus {
          margin-top: 8px;
          margin-bottom: -10px;

          color: color(marriott-light-grey);
          font-size: 32px;
          text-align: center;

          pointer-events: none;
        }
      }
    }

    .warning-wrapper {
      margin-bottom: 10px;

      visibility: hidden;

      pointer-events: none;

      &.show {
        visibility: visible;
      }
    }

    &.hide {
      opacity: 0.5;

      pointer-events: none;

      .conditions-title {
        color: color(marriott-light-grey);
      }
    }
  }
}
