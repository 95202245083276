.add-tag-wrapper {
    :global {
        .MuiFormControl-root {
            .MuiInputBase-input {
                padding: 0;
            }
            .MuiOutlinedInput-notchedOutline {
                border: 0;
                font-size: 12px;
                line-height: 1;
            }
            .MuiInputBase-root {
                padding: 5px 16px;
                .MuiInputBase-input {
                    font-size: 12px;
                    line-height: 1;
                }
            }
        }
    }
}
