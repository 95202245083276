.name-input-modal {
  width: 360px;
  padding: 20px;

  .title {
    margin-bottom: 20px;

    font-weight: bold;
    font-size: 18px;
  }

  form {
    .input-wrapper {
      margin-bottom: 10px;
    }
  }
}
