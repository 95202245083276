@import "@STYLES/colors.scss";

.section-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  .content-wrapper {
    display: flex;
    width: 100%;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
  }

  .name {
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    word-break: break-word;
  }

  .action-button-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    button {
      white-space: pre;
    }
    svg {
      width: 16px;
      height: 16px;
      fill: #595959;
    }
  }

  .body-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;
  } 
  .edit-section-name {
    border: 1px solid color(marriott-light-grey);
    border-radius: 50%;

    &:hover {
      border-color: color(marriott-primary);

      button svg {
        fill: color(marriott-primary);
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
    }
  }
}
