.sign-in-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 52px;
  overflow: hidden;

  background-color: #2e3138;
  border-radius: 8px;
  box-shadow: 0 14px 32px 0 #454a5499;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 16px;

    color: white;
    font-weight: 700;
    font-size: 16px;
    text-align: center;

    &:hover {
      background-color: #454a54;
    }

    &:disabled {
      color: #adb4bd;

      background-color: #eaf3f4;

      pointer-events: none;
    }
  }
}
