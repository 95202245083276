@import "@STYLES/colors.scss";

.vendors-sidebar {
  position: relative;

  width: 100%;
  min-height: 100%;
  padding: 25px 25px 25px 0;

  background-color: color(app-white);

  .sidebar-title {
    margin-bottom: 25px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 16px;
  }
}
