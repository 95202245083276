@mixin hover-highlight($opacity: 0.2) {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: "";
    pointer-events: none;
  }

  &:hover::after {
    background-color: rgba(255, 255, 255, $opacity);
  }
}

@mixin custom-scrollbar($thumb-color: lightgrey, $track-color: transparent) {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;

    // border-radius: 4px;
  }

  scrollbar-width: thin;
  scrollbar-color: $thumb-color $track-color;
}
