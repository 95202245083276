@import "@STYLES/colors.scss";

.main {
    position: relative;
    background-color: color(app-bg);
    flex: 1 1 auto;
    display: grid;
    :global {
        .max-constraint {
            display: flex;
            flex: 1;
            flex-direction: column;
        }
    }
    .content-wrapper {
        min-height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}
