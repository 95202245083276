@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.section-body {
    $gap: 20px;
    $padding: 2px;
    padding: 0 $padding $padding $padding;

    background-color: color(marriott-primary);
    border-bottom-right-radius: $main-border-radius;
    border-bottom-left-radius: $main-border-radius;

    .base-text-warning-wrapper {
        padding: 25px 25px 0;

        background-color: color(app-white);
    }

    .section-tags {
        padding: 15px 30px;
        background-color: color(app-white);

        $tag-gap: 15px;

        .section-tags-title {
            margin-bottom: 15px;
            font-weight: 700;
            font-size: 16px;
        }

        .section-tags-list {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            align-items: center;
            .section-tag-item {
                margin: 0;
                padding: 4px 10px;
                font-size: 12px;
                line-height: 150%;
                text-align: center;
                background-color: color(app-bg);
                border: 1px solid color(marriott-light-grey-2);
                border-radius: $main-border-radius;
            }
        }
        :global .section-bodyText {
            background-color: var(--app-bg);
            border: 1px solid var(--marriott-light-grey-2);
            border-radius: 5px;
            padding: 10px;
            width: 100%;
            text-transform: capitalize;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            display: flex;
            flex-direction: column;
            ol,
            ul {
                padding-left: 16px;
            }
        }
    }

    .variants {
        padding: 15px 30px;

        background-color: color(app-white);
        border-bottom-right-radius: $main-border-radius - 1px;
        border-bottom-left-radius: $main-border-radius - 1px;

        .variants-title {
            margin-bottom: 15px;
            font-weight: 700;
        }

        .variants-list {
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

            .variant-item {
                position: relative;
                padding: 10px 15px;
                overflow: hidden;
                background-color: color(app-white);
                border: 1px solid color(marriott-light-grey);
                border-radius: $main-border-radius;
                cursor: pointer;

                &:hover {
                    $hover-color: color(app-green);

                    border-color: $hover-color;
                    box-shadow: 0 0 0 1px $hover-color;
                }

                .variant-warning-wrapper {
                    margin-bottom: 20px;
                }

                &.alert-bar {
                    &:after {
                        content: "";
                        position: absolute;
                        top: 3px;
                        left: 0;
                        width: 6px;
                        height: calc(100% - 6px);
                        background-color: var(--app-yellow);
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }

        .no-variants {
            color: color(marriott-light-grey);
        }

        .add-variant-button-bottom {
            padding-top: 30px;
        }
    }
}
