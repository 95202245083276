@import "@STYLES/colors.scss";

.introduction-text-info {
  position: relative;

  height: 100%;

  .info {
    position: sticky;

    .warning-wrapper {
      margin-bottom: 30px;
    }

    .delimiter {
      width: 50%;
      height: 1px;
      margin: 30px 0;

      background-color: color(marriott-light-grey-2);
    }

    .title {
      margin-bottom: 30px;

      color: color(marriott-light-grey);
      font-weight: 700;
      font-size: 18px;
    }
  }
}
