@import "@STYLES/colors.scss";
@import "@STYLES/mixins.scss";

.square-button {
    position: relative;

    width: 100%;
    height: 100%;

    background-color: color(marriott-primary);
    border: 2px solid transparent;
    border-radius: 5px;

    @include hover-highlight(0.1);

    .lock-wrapper {
        position: absolute;
        top: -20px;
        right: -20px;
        z-index: 1;
    }

    .content {
        .titleWrap {
            color: white;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            white-space: pre-line;
            text-align: right;
        }
        .image-wrap {
            height: 140px;
            width: 100%;
        }
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        padding: 30px;

        color: white;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        white-space: pre-line;
        text-align: right;
    }

    &.highlighted {
        background-color: color(app-green);
    }

    &.locked {
        background-color: color(app-white);
        border: 2px solid color(marriott-light-grey);

        pointer-events: none;

        .content {
            color: color(marriott-light-grey);
        }
    }
}
