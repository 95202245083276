.sso {
  width: 100%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52px;
    padding: 0 10px;

    color: #4eb0c2;
    font-weight: 700;
    text-decoration: none;

    font-size: 14px;

    border: 1px solid #d9dde2;

    border-radius: 8px;

    &:hover {
      border-color: #adb4bd;
    }
  }
}
