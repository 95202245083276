@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
.content-with-sidebar {
  display: flex;
  flex: 1;
  display: flex;
  flex-direction: column;

  .layout {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      padding: 20px 30px;      
      border-left: 1px solid color(marriott-light-grey-2); 
      @media (max-width: 1200px) {
        margin-left: 30px;
      }
    }
  }
}
