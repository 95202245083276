@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.variant-history-modal {
  $modal-header-height: 60px;
  height: 100%;

  overflow: hidden;

  border-radius: $main-border-radius;

  .header-wrapper {
    height: $modal-header-height;
  }

  .content {
    width: 100%;
    height: calc(100% - #{$modal-header-height});

    overflow-x: hidden;
    overflow-y: auto;

    background-color: color(app-bg);

    @include custom-scrollbar($thumb-color: transparent, $track-color: rgba(249, 249, 249, 1));

    &:hover {
      @include custom-scrollbar($thumb-color: lightgrey, $track-color: rgba(249, 249, 249, 1));
    }
  }
}
