.document-permission-wraper {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    padding-left: 32px;
    &::after {
        content: '';
        position: absolute;
        height: 8px;
        width: 8px;
        background-color: #E4E7EC;
        border-radius: 100%;
        left: 0;
        top: 50%;
        transform: translate(0,-50%);
    }
    button {
        border-color: #1890FF;
        color: #1890FF;
        gap: 8px;
        padding: 5px 10px;
        line-height: 1;
        height: 32px;
        :global {
            .MuiButton-icon {
                margin: 0;
            }
        }
    }
}
