@import "@STYLES/colors.scss";

.tag-checkbox {
  display: flex;
  width: 100%;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 15px;

    background-color: color(app-bg);
    border: 1px solid color(marriott-light-grey-2);
    border-radius: 3px;
    cursor: pointer;

    span {
      font-size: 12px;
      line-height: 150%;
      text-align: center;

      user-select: none;
    }
  }

  input[type="checkbox"]:checked + label {
    background-color: color(marriott-primary);
    border-color: transparent;

    span {
      color: color(app-white);
    }
  }
}
