@import "@STYLES/design.scss";

.page {
  display: flex;
  flex-direction: column;
  flex: 1;
  h1 {
    margin: 0 0 30px;
  }

  .content {
    width: 380px;

    .tabs-wrapper {
      margin-bottom: 20px;
    }

    .input-wrapper {
      margin-bottom: 35px;
    }
  }
}
