@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.mandatory {
  padding-top: 50px;
  padding-bottom: 50px;

  .body {
    display: flex;
    flex: 1;

    border: 1px solid color(marriott-light-grey-2);
    border-radius: $main-border-radius;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    .body-wrapper {
      display: flex;
      flex: 1;

      background-color: color(app-white);
      border-radius: $main-border-radius;

      @media (max-width: 1200px) {
        flex-direction: column;
      }

      .conditions {
        display: flex;
        flex: 1;
        padding: 25px;

        .conditions-wrapper {
          position: relative;

          flex: 1;
          width: 0;

          transition: opacity 0.2s ease-in-out;

          .conditions-list {
            $gap: 25px;

            display: flex;
            flex-wrap: wrap;

            margin-right: -$gap;
            margin-bottom: -$gap;

            .condition-item-wrapper {
              width: calc(calc(100% / 4) - #{$gap});
              margin-right: $gap;
              margin-bottom: $gap;

              @media (max-width: 1700px) {
                width: calc(calc(100% / 3) - #{$gap});
              }

              @media (max-width: 1500px) {
                width: calc(calc(100% / 2) - #{$gap});
              }

              @media (max-width: 900px) {
                width: calc(100% - #{$gap});
              }
            }
          }

          .no-conditions {
            margin-bottom: 40px;

            color: color(marriott-light-grey);
            font-size: 14px;
            text-align: center;
          }

          &.hide {
            opacity: 0.5;

            pointer-events: none;
          }
        }
      }
    }
  }
}
