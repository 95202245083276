@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.basic-input {
  position: relative;

  display: flex;
  flex-direction: column;

  label {
    display: block;

    .input-label {
      margin-bottom: 6px;

      font-weight: 700;
      font-size: 12px;
    }

    input[type="text"] {
      width: 100%;
      height: 36px;
      padding: 0 15px;

      font-weight: normal;
      font-size: 12px;

      border: 1px solid color(marriott-light-grey);
      border-radius: $main-border-radius;

      &::placeholder {
        color: color(marriott-light-grey);
        font-size: 12px;
      }
    }
  }

  &.validatable {
    $pb: 20px;

    padding-bottom: $pb;

    &.error {
      //

      label input[type="text"] {
        border: 1px solid color(marriott-orange);
      }

      .error-message {
        position: absolute;
        top: calc(100% - #{$pb});

        display: flex;
        align-items: flex-end;
        width: 100%;
        height: $pb;

        color: color(marriott-orange);
        font-size: 12px;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .errors-list {
      display: none;
    }
  }
}
