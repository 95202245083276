@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.document {
  $main-color: color(marriott-primary);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 2px;

  background-color: $main-color;
  border-radius: $main-border-radius;

  .document-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    .header {
      display: flex;
    }

    .title {
      color: color(app-white);
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
    }

    .document-owner {
      display: flex;
      align-items: center;
      font-size: 12px;

      .owner {
        margin-right: 5px;

        color: color(app-white);
      }

      .name {
        color: color(app-white);
        font-weight: 700;
      }
    }
  }

  .chapters {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 2px 2px 0;
    padding: 15px;

    background-color: color(app-white);
    border-radius: $main-border-radius;

    .chapters-title {
      margin-bottom: 20px;

      font-weight: 700;
      font-size: 14px;
    }

    .chapters-list {
      //

      .chapter-item {
        width: fit-content;

        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .no-chapters {
      color: color(marriott-light-grey);
      font-size: 14px;
    }
  }
}
