@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.base-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100vw;
  height: 100vh;

  .bg {
    width: 100%;
    height: 100%;

    background-color: rgb(0 0 0 / 60%);
  }

  .content {
    position: absolute;
    left: 50%;

    max-height: calc(100vh - 60px);

    background-color: color(app-white);
    border-radius: $main-border-radius;
    transform: translateX(-50%);
  }
}
