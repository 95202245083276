@import "@STYLES/colors.scss";
.header-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e4e7ec;
    max-width: 140px;
    border-radius: 12px;
    gap: 1px;
    width: 100%;
    height: 172px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    white-space: pre;
    .upload-image {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        img {
            max-width: 100%;
            width: 100%;
            height: auto;
            object-fit: cover;
        }
        span {
            color: #667085;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
        }
        .icon-wrap {
            padding: 15px 10px 15px 10px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            justify-content: center;
        }
    }
    .image-action-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: unset;
        gap: 8px;
        width: 100%;

        &.upload-img-text {
            position: absolute;
            bottom: 5px;
            right: 5px;
            width: 100%;
            padding-left: 12px;
            button {
                background-color: #fff;
                color: #595959;
                border: 1px solid #d9d9d9;
                box-shadow: none;
                padding: 6px 16px;
                border-radius: 6px;
                font-size: 12px;
                line-height: 14px;
                font-weight: 400;
                text-transform: capitalize;
                color: var(--marriott-primary);
            }
        }
    }
    label {
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        text-transform: capitalize;
        background-color: #fff;
        color: #595959;
        border: 1px solid #d9d9d9;
        box-shadow: none;
        padding: 3px 10px;
        border-radius: 6px;
        margin: 0 10px 15px 10px;
        &:hover {
            box-shadow: none;
            background-color: var(--app-bg);
        }
        &.update-image {
            margin: 0;
            padding: 0;
            min-width: 32px;
            min-height: 32px;
            display: flex;
            align-items: center;
            border-radius: 100%;
            :global {
                .MuiButton-icon {
                    margin: 0;
                }
            }
            .details-wrap {
                white-space: nowrap;
                display: none;
            }
        }
    }
    .closeIcon {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        min-width: auto;
        padding: 0;
        border: none;
        background: transparent;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 12px;
            height: 12px;
        }
        &.preview-icon {
            cursor: default;
        }
    }
}
