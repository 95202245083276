@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.content-with-full-height-sidebar {
  $top: $subheader-height + $admin-breadcrumbs-height;
  $width: 350px;
  display: flex;
  height: 100%;

  .sidebar-header-wrapper {
    position: absolute;
    top: 0;
    z-index: 3;
    width: $width - 1px;
    min-width: $width - 1px;
    height: 100%;
    pointer-events: none;
    @media (max-width: 1200px) {
      width: $width - 51px;
      min-width: $width - 51px;
    }

    .sidebar-sticky {
      position: sticky;
      top: $top;
      width: 100%;
      height: $sidebar-header-height;
      max-height: $sidebar-header-height;
      padding-right: 25px;
      background-color: color(app-white);
      border-bottom: 1px solid color(marriott-light-grey-2);
      box-shadow: $main-box-shadow;
      pointer-events: all;
    }
  }

  .full-height-sidebar {
    position: sticky;
    top: $top;
    z-index: 1;
    width: $width;
    min-width: $width;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: color(app-white);
    border-right: 1px solid color(marriott-light-grey-2);
    box-shadow: $main-box-shadow;

    &.with-header {
      padding-top: $sidebar-header-height;
    }

    @include custom-scrollbar($thumb-color: transparent, $track-color: rgba(249, 249, 249, 1));

    &:hover {
      @include custom-scrollbar($thumb-color: lightgrey, $track-color: rgba(249, 249, 249, 1));
    }

    @media (max-width: 1200px) {
      width: 300px;
      min-width: 300px;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }
}
