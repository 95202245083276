@import "@STYLES/colors.scss";

.chapter-tags-controls {
    display: flex;
    align-items: center;
    flex: 1;
    .select-wrapper {
        position: relative;
        flex: 1;
        width: 100%;
        margin-right: 10px;
    }

    .buttons-wrapper {
        display: flex;
        align-self: flex-end;

        .button {
            padding-left: 10px;

            &:not(:last-child) {
                margin-right: 10px;
                padding-right: 20px;

                border-right: 1px solid color(marriott-light-grey-2);
            }
        }
    }
    :global {
        .custom-control {
          width: 100%;
          min-height: 28px;
          height: 28px;
          border: 0;
          line-height: 20px;
            // max-width: fit-content;
            .custom-dropdown-icon {
                display: none;
            }
        }
        .custom-menu {
            min-width: 250px;
            border: 0;
            box-shadow: 0px 9px 14px -2px #0000004a;
            z-index: 1000;
        }
    }
}
