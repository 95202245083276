@import "@STYLES/colors.scss";

.document-name {
    padding: 25px 55px 34px 55px;
    box-sizing: border-box;
    background-color: color(app-white);

    .name-wrapper {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
        align-items: center;

        .preview-document {
            margin-left: 10px;
        }

        .edit-document-name,
        .preview-document {
            display: flex;
            align-items: center;

            border: 1px solid color(marriott-light-grey);
            border-radius: 50%;

            &:hover {
                border-color: color(marriott-primary);

                button svg {
                    fill: color(marriott-primary);
                }
            }

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 34px;
                height: 34px;
            }
        }
    }

    .document-header-wrapper {
        display: flex;
        align-items: center;
        gap: 30px;
        .document-main-wrap {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .name {
                font-weight: 600;
                font-size: 26px;
                line-height: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 15px;
                .action-button-wrapper {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    button {
                        padding: 6px 14px;
                        gap: 8px;
                        svg {
                            height: 16px;
                            width: 16px;
                            span {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
        .document-owner {
            display: flex;
            align-items: center;
            gap: 14px;
            font-size: 14px;

            .owner {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: #000000d9;
            }

            .avatar-wrap {
                display: flex;
                align-items: center;
                color: color(marriott-primary);
                font-weight: 700;
                gap: 6px;                //styleName: Body/regular;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                color: #1f1f1f;
                padding:0 10px 0 0;
                :global {
                    .MuiAvatar-circular {
                        height: 34px;
                        width: 34px;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 22px;
                        color: #fff;
                        background-color: #b37feb;
                    }
                }
            }
        }
    }
}
