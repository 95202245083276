@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

$main-color: color(marriott-primary);

.vendor {
  background-color: color(app-white);
  border: 1px solid color(marriott-light-grey-2);
  border-radius: $main-border-radius;

  .vendor-wrapper {
    padding: 2px;

    background-color: $main-color;
    border-radius: $main-border-radius;
  }
}
