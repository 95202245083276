@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.drawer {
  //

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    width: 100vw;
    height: 100vh;

    background-color: rgb(0 0 0 / 60%);
    opacity: 0;

    transition: opacity 0.3s ease-in-out;

    &.show {
      opacity: 1;
    }
  }

  .drawer-wrapper {
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 11;

    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    background-color: color(app-white);
    border-left: 1px solid color(marriott-light-grey-2);
    box-shadow: $main-box-shadow;

    transition: right 0.4s ease-in-out;

    &.show {
      right: 0;
    }

    @include custom-scrollbar($thumb-color: transparent);

    &:hover {
      @include custom-scrollbar($thumb-color: lightgrey);
    }
  }
}
