.tree-list {
  .tree-item {
    list-style: none;
    width: 100%;
    padding: 12px 20px;
    border-radius: 5px;
    background: #31697a;
    color: #fff ;
    .item-content {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      color: #fff;
      span {
        color: #fff;
        &.item-number {
          margin-right: 15px;
          padding-right: 15px;
          color: var(--app-white);
          font-weight: 700;
          font-size: 14px;
          border-right: 1px solid var(--app-white);
        }
      }
    }
    .subsection-wrapper {
      .subSection-item {
        margin-top: 12px;
        background-color: #00a4ba;
        .submenu-wrapper {
          margin-top: 12px;
          .subMenu-list {
              > div {
                margin-bottom: 0;
              }
            .subMenu-item {
              background: #72d6e5;
            }
          }
        }
      }
    }
  }
}

// .tree-item {
//     margin: 0.5rem 0;
//     padding: 0.5rem;
//     border: 1px solid #ccc;
//     border-radius: 4px;
// }

// .item-content {
//     display: flex;
//     gap: 0.5rem;
//     align-items: center;
// }

// .tree-item.dragged {
//     background-color: #f0f0f0;
// }
