@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.modal-body {
  $info-width: 300px;
  $info-width-less: 250px;
  position: relative;

  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  .sidebar {
    display: flex;
    width: $info-width;
    min-width: $info-width;
    height: 100%;

    background-color: color(app-white);
    border-right: 1px solid color(marriott-light-grey-2);
    box-shadow: $main-box-shadow;
  }

  .body {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    transform: translateY(-1px);

    @include custom-scrollbar($thumb-color: transparent, $track-color: rgba(249, 249, 249, 1));

    &:hover {
      @include custom-scrollbar($thumb-color: lightgrey, $track-color: rgba(249, 249, 249, 1));
    }
  }
}
