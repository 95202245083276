@import "@STYLES/colors.scss";

.document-preview-page {
  position: relative;

  display: flex;
  min-height: 100px;
  margin: 0 auto;
  overflow: hidden;

  background-color: color(app-white);
  box-shadow: 0 0 5px rgb(0 0 0 / 15%);

  .body {
    //

    .iframe-wrapper {
      position: absolute;

      display: flex;

      visibility: hidden;

      iframe {
        margin: 0;

        border: none;
      }
    }

    &.is-loaded {
      .iframe-wrapper {
        position: static;

        visibility: visible;
      }
    }
  }

  .document-page-preloader {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 15px;
  }
}
