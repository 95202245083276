.accordion-wrapper {
    :global {
        .MuiButtonBase-root {
            .MuiAccordionSummary-content {
                margin: 0;
                font-weight: 400;
                color: #344054;
                font-size: 13px;
            }
            .MuiAccordionSummary-expandIconWrapper {
                transform: rotate(90deg);
                padding: 10px;
            }
            .Mui-expanded {
                &.MuiAccordionSummary-expandIconWrapper {
                    transform: rotate(270deg);
                }
            }
        }

        .accordion-details {
            background-color: #f9fafb;
        }

        .accordion-summary {
            min-height: 48px;
            flex-direction: row;
            gap: 10px;
            padding: 0 24px;
            border-top: 1px solid var(--marriott-light-grey-2);

            &.first-accordion {
                border-color: transparent;
            }
            &.remove-icon {
                .MuiAccordionSummary-expandIconWrapper {
                    display: none;
                }
            }
            &.Mui-expanded {
                min-height: 48px;
                border-bottom: 1px solid transparent;
                background-color: #f9fafb;
                .MuiAccordionSummary-content {
                    color: #3496b0;
                    font-weight: 500;
                }
            }
            svg {
                height: 13px;
                width: 13px;
                path {
                    fill: #d0d5dd;
                }
            }

            &:hover {
                .accordion-title-wrapper {
                    .accordion-title {
                        background-color: transparent;
                        color: #3496b0;
                    }
                }
                svg {
                    path {
                        fill: #3496b0;
                    }
                }
            }

            .accordion-title-wrapper {
                .accordion-title {
                    font-size: 13px;
                    padding: 5px 20px 5px 5px;
                    text-transform: none;
                    color: var(--marriott-dark-grey);
                    padding: 0;
                    line-height: 1.5;
                    letter-spacing: 0.00938em;
                    text-align: left;
                    &:hover {
                        .MuiAccordionSummary-content {
                            color: #3496b0;
                        }
                        svg {
                            path {
                                fill: #3496b0;
                            }
                        }
                    }
                }
            }
            .MuiAccordionSummary-content {
                margin: 0;
                padding: 5px 0;
            }
            .accordion-icon{
                padding: 12px;
            }
        }
        .MuiPaper-root {
            box-shadow: none;
        }

        .MuiCollapse-wrapper {
            .MuiCollapse-wrapperInner {
                .MuiAccordionDetails-root {
                    padding: 0 24px 0 48px;
                    ul {
                        list-style: none;
                        li {
                            padding: 6px 0;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 16px;
                            border-top: 1px solid var(--marriott-light-grey-2);
                            min-height: 48px;
                            display: flex;
                            align-items: center;

                            .charpter-link {
                                color: #344054;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                &:hover {
                                    color: #1d2939;
                                    font-weight: 500;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
