@import "@STYLES/colors.scss";

.floating-publish-button {
  height: 100%;
  .publish-wrapper {
    height: 100%;
    border-left: 1px solid transparent;
    transition: margin-right 0.1s ease-in-out, border-color 0.2s ease-in-out;
    pointer-events: all;
    button {
      color: #fff;
      background-color: #FFC53D;
      padding: 5px 12px;
      height: 34px;
      gap: 10px;
      border-radius: 5px;
    }
  }

    &.to-right {
        .publish-wrapper {
            margin-right: 0;
            border-color: color(marriott-light-grey-2);
        }
    }
}
