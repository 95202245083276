@import "@STYLES/colors.scss";

.open-close-button {
  $size: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;

  border-radius: 50%;

  $width: 8px;
  $thickness: 2px;

  .expand {
    position: relative;

    width: $width;
    height: $thickness;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: $thickness;
      height: $width;

      background-color: inherit;
      transform: translate(-50%, -50%);

      content: "";
    }
  }

  .collapse {
    width: $width;
    height: $thickness;
  }
}
