@import "@STYLES/colors.scss";

.route-button {
    $main-color: color(marriott-primary);
    display: flex;
    align-items: center;

    button {
        display: flex;
        gap: 10px;
        .title {
            font-size: 12px;
            line-height: 18px;
            text-align: left;
            p {
                svg {
                    top: 3px;
                    right: -2px;
                    position: relative;
                }
            }
        }

        .edit-icon {
            display: flex;
        }

        &:hover {
            .title {
                color: $main-color;
                text-decoration: underline;
            }

            .edit-icon {
                svg {
                    fill: $main-color;
                }
            }
        }
    }

    &.active {
        button {
            cursor: default;

            .title {
                color: $main-color;
                text-decoration: none !important;
            }

            .edit-icon svg {
                fill: $main-color;
            }
        }
    }
}
