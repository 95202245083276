@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.vendor-body {
  overflow: hidden;

  border-radius: $main-border-radius - 1px;

  .body-wrapper {
    $padding: 30px;
    display: flex;
    justify-content: space-between;

    background-color: color(app-white);

    .vendor-data-wrapper {
      width: 100%;

      padding: $padding;

      background-color: color(app-white);
      border-right: 1px solid color(marriott-light-grey-2);
    }

    .products {
      width: 100%;
      padding: $padding;

      .products-title {
        margin-bottom: 20px;

        color: color(marriott-primary);
        font-weight: 700;
        font-size: 14px;
      }

      .products-list {
        $gap: $padding;

        display: flex;
        flex-wrap: wrap;

        margin-right: -$gap;
        margin-bottom: -$gap;

        .product-wrapper {
          width: calc(50% - #{$gap});
          margin-right: $gap;
          margin-bottom: $gap;
        }
      }
    }
  }
}
