@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.tag-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: row-reverse;
  padding:4px 10px;
  border-radius: 10px;
  background-color: color(app-bg);
  border: 1px solid #D9D9D9;
  border-radius: $main-border-radius;
  width: 100%;
  height: 30px;
  .tag-name {
    font-size: 12px;
    font-weight: 400;
    color: #000000D9;
    line-height: 1;
  }

  .progress-indicator-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .lock-wrapper {
    position: absolute;
    top: -14px;
    right: -14px;
    z-index: 1;
  }

  .delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: #00000040;
    font-size: 14px;
    line-height: 1;
    &:hover {
      background-color: #00a4ba;
     color: #fff;
    }
  }

  &.locked {
    background-color: color(app-white);
    border: 1px solid color(marriott-light-grey);

    .tag-name {
      color: color(marriott-light-grey);
    }
  }

  &.is-processing {
    .tag-name {
      opacity: 0.2;
    }

    .delete-button {
      color: color(marriott-light-grey);
      pointer-events: none;
    }
  }
}
