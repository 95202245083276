@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.chapter {
    $main-color: color(marriott-primary);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 2px;

    background-color: $main-color;
    border-radius: $main-border-radius;

    .chapter-info-wrapper {
        position: relative;

        padding: 20px;

        .accent {
            position: absolute;
            top: 3px;
            left: 0;

            width: 6px;
            height: calc(100% - 3px);

            background-color: color(app-yellow);
            border-top-right-radius: $main-border-radius;
            border-bottom-right-radius: $main-border-radius;
        }

        .header {
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: space-between;
            margin: 0 0 10px 0;
            .chapter-number {
                display: flex;

                color: color(app-white);
                font-weight: 700;
                font-size: 14px;
            }
        }

        .title {
            color: color(app-white);
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
        }
    }

    .sections {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 2px 2px 0;
        padding: 15px;

        background-color: color(app-white);
        border-radius: $main-border-radius;

        .introduction-text-warning {
            margin-bottom: 0;
        }

        .add-section-btn {
            width: fit-content;
            padding: 8px 0;
            button {
                padding: 4px 5px;
                display: flex;
                align-items: center;
                border-color: #1890ff;
                border-radius: 5px;
                min-height: 24px;
                :global .custom-btn-title {
                    color: #1890ff;
                }
            }
        }

        .sections-title {
            margin: 0 0 10px 0;

            font-weight: 700;
            font-size: 14px;
        }

        .sections-list {
            //

            .section-item {
                display: flex;
                width: 100%;
                justify-content: space-between;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                .warning-icon {
                    margin-left: 8px;
                }
                button {
                    padding: 4px 5px;
                    display: flex;
                    align-items: center;
                    border: none;
                    min-height: 24px;
                    :global .custom-btn-title {
                        color: #1890ff;
                    }
                }
            }

            :global {
                .outer-accordion {
                    border-bottom: 1px solid var(--marriott-light-grey-2) !important;
                    &.Mui-expanded {
                        border-bottom: 0 !important;
                    }
                }
                .custom-subsection-button-wrapper {
                    padding-top: 0;
                    border: 0;
                }
                .lastButton {
                    border-bottom: 1px solid var(--marriott-light-grey-2);
                    padding: 0;
                }
                .custom-accordion-summary {
                    min-height: auto;
                    padding: 0 24px 0 0;
                    flex-direction: row-reverse;
                    background-color: #fff !important;
                    border: 0;
                    gap: 5px;
                    .MuiAccordionSummary-expandIconWrapper {
                        padding: 0;
                        transform: rotate(0deg);
                    }
                    .Mui-expanded {
                        &.MuiAccordionSummary-expandIconWrapper {
                            transform: rotate(90deg);
                        }
                    }
                    .custom-accordion-icon {
                        padding: 7px 0 7px 0;
                    }

                    svg {
                        height: 10px;
                        width: 10px;
                    }
                }
                .custom-accordion-details {
                    background: #fff;
                    border-bottom: 1px solid var(--marriott-light-grey-2);
                }
                .clear-button {
                    background: transparent;
                    border: 0;
                    width: fit-content;
                }

                .accordion-title {
                    background: #fff;
                    text-align: left;
                    justify-content: left;
                }
            }
        }

        .no-sections {
            color: color(marriott-light-grey);
            font-size: 14px;
        }
    }
}
