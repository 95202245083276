@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.section-header {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;

  background-color: color(marriott-primary);
  border-top-left-radius: $main-border-radius;
  border-top-right-radius: $main-border-radius;
  cursor: pointer;

  &.is-close {
    border-bottom-right-radius: $main-border-radius;
    border-bottom-left-radius: $main-border-radius;
  }

  .accent {
    position: absolute;
    top: 3px;
    left: 0;

    width: 6px;
    height: calc(100% - 6px);

    background-color: color(app-yellow);
    border-top-right-radius: $main-border-radius;
    border-bottom-right-radius: $main-border-radius;
  }

  .header-buttons {
    display: flex;
    padding-left: 20px;
  }

  .title-wrapper {
    display: flex;
    align-items: center;

    .buttons {
      display: flex;
      align-items: center;

      .triple-buttons-wrapper {
        margin-right: 15px;
      }

      .button-wrapper {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }

    .title {
      margin-left: 20px;

      color: color(app-white);
      font-weight: bold;
      font-size: 14px;
    }
  }
}
