@import "@STYLES/colors.scss";

.view-mode {
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-left: 25px;

  border-left: 1px solid color(marriott-light-grey-2);

  .view-mode-title {
    margin-left: 15px;

    font-weight: 700;
    font-size: 14px;
  }

  &.disabled {
    pointer-events: none;

    .view-mode-title {
      color: color(marriott-light-grey);
    }
  }
}
