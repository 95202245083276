@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.variant-body-footer {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;

  background-color: color(app-white);
  border-top: 1px solid color(marriott-light-grey-2);
  box-shadow: $main-box-shadow;

  .tags-wrapper {
    padding: 15px 25px;

    border-bottom: 1px solid color(marriott-light-grey-2);
    :global .custom-tag-container{
      margin: 0;
    }
  }

  .vendors-wrapper {
    padding: 25px;
  }
}
