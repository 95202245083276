@import "@STYLES/colors.scss";

.chapters-view {
    margin-top: 10px;
    border-bottom: 1px solid var(--marriott-light-grey-2);
    .chapters-title {
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
            height: 20px;
        }
        padding: 16px 24px;
        color: #1d2939;
        font-weight: 700;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        border-bottom: 1px solid transparent;
        min-height: 51px;

        &:hover {
            svg {
                cursor: pointer;
            }
        }
    }

    .chapters-list {
        .chapter-list-item {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
}
