.login-form {
  display: flex;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 366px;
    max-width: 100%;
    padding: 15px;

    .title {
      margin-bottom: 24px;

      color: #454a54;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -1px;
      text-align: center;
    }

    .sso-wrapper {
      width: 100%;
    }

    .or {
      padding: 16px 0;

      color: #202020;

      font-size: 14px;
      line-height: 20px;
    }

    form {
      width: 100%;
      margin-bottom: 16px;

      .input-wrapper {
        width: 100%;

        &:not(:last-of-type) {
          margin-bottom: 4px;
        }
      }

      .error-message {
        display: flex;
        align-items: center;
        min-height: 20px;
        padding: 2px 0;

        color: #df715f;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
