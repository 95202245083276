@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.base-text {
  display: flex;
  width: 100%;
  height: 100%;

  .checkbox-wrapper {
    margin: 0 20px;

    .checkbox {
      position: sticky;
      top: 51px;

      padding-top: 5px;
    }
  }

  .base-text-body {
    width: 100%;
    margin-right: 20px;
    overflow: hidden;

    border: 1px solid color(marriott-light-grey-2);
    border-radius: $main-border-radius;
  }
}
