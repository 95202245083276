@import "@STYLES/colors.scss";

.section-sticky-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;

  background-color: color(app-white);

  .title {
    position: absolute;

    color: color(marriott-light-grey);
    font-size: 12px;
  }

  .name {
    width: 100%;

    color: color(marriott-primary);
    text-align: center;
  }
}
