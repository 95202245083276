@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .title-wrapper {
    display: flex;
    align-items: center;

    .title {
      font-weight: bold;
      font-size: 18px;
      white-space: nowrap;
    }

    .prev-next {
      display: flex;
      margin-left: 15px;

      .prev-button {
        .icon {
          transform: rotate(-180deg);
        }
      }

      .prev-button,
      .next-button {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          display: flex;
          align-items: center;

          svg {
            fill: color(marriott-dark-grey);
          }
        }

        &:not(:last-child) {
          margin-right: 10px;
        }

        &:disabled {
          pointer-events: none;

          .icon svg {
            fill: color(marriott-light-grey);
          }
        }

        &:hover {
          .icon svg {
            fill: color(marriott-primary);
          }
        }
      }
    }
  }

  .section-name {
    padding: 0 20px;
    overflow: hidden;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .right-block {
    display: flex;

    .pdf-button-wrapper {
      margin-right: 30px;
      margin-left: auto;
    }

    .close-button-wrapper {
      display: flex;
      align-items: center;
      height: 100%;

      .close-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;

        font-size: 30px;
      }
    }
  }
}
