@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.content {
    padding-bottom: 60px;

    .mandatory-subheader,
    .section-tags-subheader,
    .section-vendors-subheader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;

        background-color: color(app-white);

        .title {
            color: color(marriott-primary);
            font-weight: 700;
            font-size: 14px;
        }
    }

    .accordion-wrap {
        position: relative;
        z-index: 1;
    }

    .vendors-wrap {
        .vendors-text {
            background: #fff;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--marriott-light-grey-2);
            height: 40px;
            flex: 1;
            color: var(--marriott-primary);
            font-weight: 700;
            font-size: 18px;
            padding: 0 0 0 50px;
        }
          :global {
            .customVendorClassNames {
              background: color(app-white)
            }
          }
    }

    .variants-list {
        $gap: 120px;
        $gap-less: 20px;

        margin-top: 50px;

        .add-variant-button {
            width: 100%;
            height: 60px;
            margin: 60px 0;
        }

        .variant-item-wrapper {
            position: relative;
            .delimiter {
                position: absolute;
                top: 0;
                right: calc(100% + 50px);
                z-index: 1;

                width: 350px;
                height: 1px;

                background-color: color(marriott-light-grey-2);

                @media (max-width: 1200px) {
                    right: calc(100% + 20px);

                    width: 330px;
                }
            }

            &:not(:last-child) {
                margin-bottom: $gap;
            }

            &.is-close {
                .delimiter {
                    top: (-$gap-less + 2px) / 2;

                    visibility: hidden;
                }

                &:not(:last-child) {
                    margin-bottom: $gap-less;
                }
            }
        }
    }
    .action-buttons {
        display: flex;
        white-space: pre;
        .mandatory-button {
            border: 0;
            text-transform: none;
        }
    }
}
