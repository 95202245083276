@import "@STYLES/colors.scss";

.preview-icon-wrapper {
  display: flex;
  align-items: center;
  margin-left: 85px;
}

.publish-button-wrapper {
  margin-left: 15px;
}

.chapter-not-found {
  padding-top: 30px;

  color: color(marriott-light-grey);
  font-weight: 700;
  font-size: 14px;
  text-align: center;
}

.page {
  //

  h2 {
    margin: 0 0 20px;
  }
}
