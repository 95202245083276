@import "@STYLES/colors.scss";

.variant-header {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  cursor: pointer;

  .title-wrapper {
    display: flex;
    align-items: center;

    .buttons {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .button-wrapper {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }

    .title {
      color: color(app-white);
      font-weight: 700;
      font-size: 14px;
    }
  }

  .open-close-button-wrapper {
    display: flex;
  }
}
