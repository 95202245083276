@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.condition-item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px 15px;

  background-color: color(app-bg);
  border: 1px solid color(marriott-light-grey-2);
  border-radius: $main-border-radius;

  .progress-indicator-wrapper {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .lock-wrapper {
    position: absolute;
    top: -14px;
    right: -14px;
    z-index: 1;
  }

  .delete-button {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    color: color(marriott-orange);
    font-size: 24px;
  }

  .item-wrapper {
    //

    .value-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title,
      .value {
        line-height: 150%;
      }

      .title {
        color: color(marriott-light-grey);
        font-size: 12px;
        font-style: italic;
      }

      .value {
        color: color(marriott-primary);
        font-weight: 700;
        font-size: 14px;
      }
    }

    .plus {
      width: 100%;
      margin: 8px 0;

      color: color(marriott-light-grey);
      font-size: 32px;
      text-align: center;
    }

    &:last-child {
      .plus {
        display: none;
      }
    }
  }

  &.locked {
    background-color: color(app-white);
    border: 1px solid color(marriott-light-grey);

    .item-wrapper {
      .value-wrapper .value {
        color: color(marriott-light-grey);
      }
    }
  }

  &.is-processing {
    .value-wrapper {
      opacity: 0.2;
    }

    .delete-button {
      color: color(marriott-light-grey);

      pointer-events: none;
    }
  }
}
