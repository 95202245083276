@import "@STYLES/colors.scss";

.pair-toggle-buttons {
  display: flex;

  .on-button {
    border-right: 1px solid color(marriott-light-grey);
  }

  .off-button {
    //
  }

  .on-button,
  .off-button {
    padding: 0 10px;

    color: rgb(0 0 0 / 50%);
    font-size: 12px;

    &:hover {
      text-decoration: underline;
    }
  }
}
