@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/pdf.scss";

.modal-body {
  $info-width: 330px;
  $info-width-less: 250px;
  position: relative;

  display: flex;
  flex: 1;
  width: 100%;

  .pseudo-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;

    width: $info-width;

    background-color: color(app-white);
    border-right: 1px solid color(marriott-light-grey-2);
    box-shadow: $main-box-shadow;
  }

  .pages {
    width: 100%;

    .pages-wrapper {
      $gap: 100px;

      padding: 30px 0 60px;

      .item-wrapper {
        position: relative;
        z-index: 1;

        display: flex;

        &:not(:last-child) {
          margin-bottom: $gap;

          .info-wrapper::before {
            position: absolute;
            bottom: -$gap / 2;
            left: 0;

            width: 100%;
            height: 1px;

            background-color: color(marriott-light-grey-2);

            content: "";
          }
        }

        .info-wrapper {
          position: relative;

          width: $info-width;
          min-width: $info-width;
          margin-right: 10px;
          padding-right: 25px;
        }

        .page-wrapper {
          width: 100%;

          .page-placeholder {
            width: $pdf-page-width;
            height: 200px;
            margin: 0 auto;

            border: 1px dashed color(marriott-light-grey);
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .pseudo-sidebar {
      width: $info-width-less;
    }

    .pages .pages-wrapper .item-wrapper {
      .info-wrapper {
        width: $info-width-less;
        min-width: $info-width-less;
        padding-right: 20px;
      }

      .page-wrapper .page-placeholder {
        width: $info-width-less;
      }
    }
  }
}
