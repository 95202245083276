.basic-multi-select {
    width: 100%;

    label {
        display: flex;
        padding-bottom: 6px;

        .title {
            font-weight: 700;
            font-size: 12px;
        }
    }

    .option {
        & * {
            color: #a7b5be;
        }

        &.selected *,
        &:hover * {
            color: #202020;
        }
    }
  .custom-value-container {
    &.custom-placeholder-value-container {
      flex-direction: row;
      input {
        padding: 0 16px;
      }
    }
    display: flex;
      flex-direction: row-reverse;
      .values {
        flex: 1;
      }
  }
}

