@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.results-counter {
  position: sticky;
  top: 0;
  z-index: 7;

  height: 39px;

  background-color: color(app-white);
  box-shadow: $main-box-shadow;

  .results-counter-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    .count {
      font-weight: 700;
      font-size: 12px;
    }

    .results-query {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: 15px;
      padding-left: 15px;

      font-size: 12px;

      border-left: 1px solid color(marriott-light-grey-2);
    }
  }
}
