.variant-vendors-controls {
    display: flex;
    align-items: center;

    .select-wrapper {
        width: 260px;
        margin-right: 10px;
    }

    .button-wrapper {
        display: flex;
        align-self: flex-end;

        button {
            background: #f2f4f7 !important;
            width: 100% !important;
            font-size: 14px !important;
            font-weight: 400;
            line-height: 22px;
            text-align: center;
            padding: 6px 16px !important;
            &::after {
                content: none;
            }
        }
    }
}
