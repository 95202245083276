@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.square-checkbox {
  position: relative;

  width: 100%;
  height: 100%;
  overflow: hidden;

  border-radius: $main-border-radius;

  transition: transform 0.1s ease-in-out;

  @include hover-highlight(0.1);

  .mandatory-label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    display: flex;
    align-items: center;
    padding: 5px 8px;

    color: color(app-white);
    font-size: 10px;

    background-color: color(marriott-orange);
  }

  label {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px 15px 20px 25px;

    background-color: color(marriott-light-grey);
    cursor: pointer;

    .title,
    .header {
      color: white;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;

      user-select: none;
    }

    .header {
      align-self: flex-end;
    }

    .icon-container {
      display: flex;
      justify-content: space-between;

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;

        background-color: white;
        border-radius: 5px;
      }

      svg {
        visibility: hidden;

        fill: color(marriott-dark-grey);
      }
    }

    .title-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: flex-start;
      justify-content: flex-end;
      padding-top: 10px;
    }
  }

  &.mandatory {
    pointer-events: none;

    label .icon-container svg {
      fill: rgb(53 57 68 / 30%);
    }
  }

  input[type="checkbox"]:checked + label {
    background-color: color(marriott-primary);

    .icon-container .icon-wrapper svg {
      visibility: visible;
    }
  }
}
