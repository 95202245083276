.email-input {
  display: flex;
  flex-direction: column;

  label {
    display: block;

    color: #454a54;

    font-size: 12px;
    line-height: 20px;
  }

  input[type="text"] {
    width: 100%;
    height: 40px;
    padding: 0 16px;

    color: #202020;
    font-size: 14px;

    background-color: white;

    border: 1px solid #d9dde2;
    border-radius: 8px;

    &::placeholder {
      color: #adb4bd;
      font-size: 14px;
    }

    &:focus {
      border-color: #adb4bd;
    }

    &:disabled {
      border-color: #d9dde2 !important;
    }

    &.error {
      border-color: #df715f;
    }
  }
}
