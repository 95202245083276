@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.vendor-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  border: 1px solid #E4E7EC;
  border-radius: $main-border-radius;

  .progress-indicator-wrapper {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .delete-button {
    border: 1px solid #E4E7EC;
    background: #E4E7EC;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 4px;

    color: color(marriott-orange);
    font-size: 24px;
    &:hover {
      background: #d6d9dd;
    }
  }

  .lock-wrapper {
    position: absolute;
    top: -14px;
    right: -14px;
    z-index: 1;
  }

  .item-body {
    padding: 18px;

    .warning {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .icon-wrapper {
        display: flex;
        align-items: center;
        margin-right: 8px;

        svg {
          fill: color(marriott-light-grey);
        }
      }

      .message {
        height: 100%;
        padding-top: 3px;

        color: color(marriott-light-grey);
        font-weight: 700;
        font-size: 12px;
      }
    }
    .header-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 12px 0;
      margin: 0 0 12px 0;
      border-bottom: 1px solid #F2F4F7;
    .item-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      letter-spacing: -0.02em;
      text-align: left;
      color: #344054;      
    }
  }

    .body-title {
      display: grid;
      grid-template-columns: 110px 1fr;

      span {
        color:#475467;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;
        
        &.name {
          color: #475467;
        }

        &.email {
          color: #2E90FA;
        }

        &:first-child {
          margin-right: 10px;
          color: #98A2B3;
        }
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  &.locked {
    background-color: color(app-white);
    border: 1px solid color(marriott-light-grey);

    .item-body {
      .item-title {
        color: color(marriott-light-grey);
      }
    }
  }

  &.is-processing {
    .item-body {
      opacity: 0.2;
    }

    .delete-button {
      color: color(marriott-light-grey);

      pointer-events: none;
    }
  }
}
