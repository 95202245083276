@import "@STYLES/colors.scss";

.round-button {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;

  background-color: color(app-white);
  border-radius: 50%;

  pointer-events: all;

  .lock-wrapper {
    position: absolute;
    z-index: 1;

    width: 100%;
    height: 100%;

    opacity: 0;

    pointer-events: all;

    &:hover {
      opacity: 1;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: color(marriott-primary);
    }
  }

  &:hover {
    .icon svg {
      filter: brightness(1.15);
    }

    .edit {
      svg {
        fill: color(marriott-primary);
      }
    }

    .copy {
      svg {
        fill: color(app-yellow);
      }
    }

    .delete {
      svg {
        fill: color(marriott-orange);
      }
    }
  }

  &.locked {
    .icon {
      svg {
        fill: color(marriott-light-grey);
      }
    }
  }
}
