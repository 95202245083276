@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
.reactMap-wrapper {
    height: 100%;
    margin-top: 32px;
    .tooltip {
        $main-color: color(marriott-dark-blue);
        z-index: 12;
        padding: 12px 18px;
        color: color(app-white);
        font-size: 13px;
        background-color: $main-color;
        border-radius: $main-border-radius;

        .arrow {
            background-color: $main-color;
        }
    }

    svg {
        width: 100%;
    }

    .controls {
        display: flex;
        justify-content: center;
        gap: 10px;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            height: 2rem;
            width: 2rem;
            background:  var(--marriott-dark-blue);
            border-radius: 100%;
            border: 0;
            
            svg {
                line {
                    color: #fff;
                }
            }
        }
    }
}
