.chapter-introduction-text {
  //

  .header {
    //

    .title {
      margin-bottom: 25px;

      font-weight: 700;
      font-size: 14px;
      text-align: center;
    }
  }

  .body-wrapper {
    //
  }
}
