@import "@STYLES/colors.scss";

.chapter-name {
    display: flex;
    width: 100%;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: color(app-white);

    .preview-chapter {
        margin-left: 10px;
    }
    .edit-chapter-name,
    .preview-chapter {
        display: flex;
        align-items: center;

        border: 1px solid color(marriott-light-grey);
        border-radius: 50%;

        &:hover {
            border-color: color(marriott-primary);

            button svg {
                fill: color(marriott-primary);
            }
        }
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
        }
    }

    .chapter-header-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 30px;
        .chapter-main-wrap {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .name {
                font-weight: 600;
                font-size: 26px;
                line-height: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 15px;
                .action-button-wrapper {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    button {
                        white-space: pre;
                    }
                }
            }
        }
    }
}
