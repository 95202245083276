.circular-progress-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .ring {
    position: relative;

    display: inline-block;

    div {
      position: absolute;

      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      border-radius: 50%;

      animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }

    div:nth-child(1) {
      animation-delay: -0.45s;
    }

    div:nth-child(2) {
      animation-delay: -0.3s;
    }

    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
