$main-header-height: 62px;
$subheader-height: 40px;
$step-breadcrumbs-height: 40px;
$admin-breadcrumbs-height: 40px;
$sidebar-header-height: 41px;
$wide-button-height: 56px;

$main-border-radius: 5px;
$main-box-shadow: rgb(0 0 0 / 10%) 0 0 3px;

$design: (
  main-header-height: $main-header-height,
  subheader-height: $subheader-height,
  step-breadcrumbs-height: $step-breadcrumbs-height,
  admin-breadcrumbs-height: $admin-breadcrumbs-height,
  sidebar-header-height: $sidebar-header-height,
  wide-button-height: $wide-button-height,
  main-border-radius: $main-border-radius,
  main-box-shadow: $main-box-shadow,
);
