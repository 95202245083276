@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.content {
    $gap: 50px;

    .document-name {
        margin-right: 15px;
        margin-bottom: 50px;

        font-weight: 700;
        font-size: 24px;
    }

    .document-permissions-subheader,
    .document-image-subheader,
    .document-tags-subheader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;

        background-color: color(app-white);

        .title {
            color: color(marriott-primary);
            font-weight: 700;
            font-size: 18px;
        }
    }

    .document-permissions-wrapper {
        //
    }

    .document-image-wrapper {
        //
    }

    .document-tags-wrapper {
        //
    }

    .grid {
        display: grid;
        // flex-wrap: wrap;
        // margin-top: 50px;
        // margin-right: -$gap;
        grid-template-columns: repeat( auto-fit, minmax(470px, 1fr) );
        gap: 46px;
        margin: 22px 0;
        .grid-item {
            width: 100%;
            // margin-right: $gap;
            // margin-bottom: $gap;

            // @media (max-width: 1600px) {
            //     width: calc(calc(100% / 2) - #{$gap});
            // }

            // @media (max-width: 1300px) {
            //     width: calc(calc(100% / 1) - #{$gap});
            //     min-width: 300px;
            // }

            .add-chapter-button {
                height: 100%;
            }
        }
    }
    :global {
        .custom-sticky-header {
            border-bottom: 0;
        }
    }
}
