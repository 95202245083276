@import "@STYLES/colors.scss";

.page-header {
  background-color: color(app-white);
  position: relative;
  .page-wrap {
    margin: 10px 40px;
    padding: 15px 15px 24px 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .chapter-options {
    display: flex;
    align-items: center;
    gap: 10px;
    :global {
    .MuiTypography-root {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    }
    .options {
      display: flex;

      .option {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-right: 30px;
          border-right: 1px solid color(marriott-light-grey-2);
        }
        .checkbox-title {
          margin-right: 15px;
          color: color(marriott-light-grey);
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
  }
}
