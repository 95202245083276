@import "@STYLES/colors.scss";

.basic-history-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .comparison-list {
    //
  }

  .no-history {
    padding: 30px 0;

    color: color(marriott-light-grey);
    font-size: 14px;
    text-align: center;
  }
}
