@import "@STYLES/colors.scss";

.pagination {
  display: flex;

  .paginate {
    display: flex;

    li {
      $size: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      width: $size;
      height: $size;

      font-weight: 700;
      font-size: 10px;

      border-radius: 50%;

      user-select: none;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .prev-icon {
          transform: translate(-1px, -1px) rotate(180deg);
        }

        .next-icon {
          transform: translate(1px, 1px);
        }
      }

      &:not(:last-child) {
        margin-right: 15px;
      }

      &:not(.break) {
        background-color: color(app-white);
        border: 1px solid color(marriott-light-grey-2);
        cursor: pointer;

        &:hover {
          background-color: color(app-bg);
        }
      }

      &.break {
        pointer-events: none;
      }

      &.selected {
        background-color: color(marriott-primary);
        border: 1px solid color(marriott-primary);

        pointer-events: none;

        a {
          color: color(app-white);
        }
      }

      &.disabled {
        pointer-events: none;

        a {
          //

          .prev-icon,
          .next-icon {
            svg {
              fill: color(marriott-light-grey-2);
            }
          }
        }
      }
    }
  }
}
