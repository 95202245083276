.autocomplete-option {
    .option-content {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 12px;
        cursor: pointer;
        :global {
            .user-avatar {
                width: 28px;
                height: 28px;
                font-size: 12px;
            }
            span {
                font-size: 13px;
                font-weight: 400;
                line-height: 22px;
            }
        }
    }
}
