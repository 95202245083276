@import "@STYLES/colors.scss";

.crumb {
  font-weight: 400;
  font-size: 12px;

  span {
    white-space: nowrap;
  }

  .content {
    &.is-active {
      pointer-events: none;

      .content-wrapper .title {
        color: color(marriott-primary);

        pointer-events: none;
      }
    }

    .content-wrapper {
      display: flex;
      align-items: center;
      white-space: nowrap;
      button:hover {
        .title {
          text-decoration: underline;
        }
      }

      .arrow {
        display: flex;
        align-items: center;
        margin: 0 15px;
      }
    }
  }
}
