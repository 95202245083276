@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.simple-large-modal-header {
  position: sticky;
  top: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px;

  background-color: color(app-white);
  border-bottom: 1px solid color(marriott-light-grey-2);
  box-shadow: $main-box-shadow;

  .title {
    font-weight: 700;
    font-size: 18px;
    white-space: nowrap;
  }

  .variant-title {
    margin-right: 110px;
    padding: 0 20px;
    overflow: hidden;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .close-button-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    .close-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;

      font-size: 30px;
    }
  }
}
