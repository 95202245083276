@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;

  border-radius: 50px;
}

.true-icon {
  //
}

.false-icon {
  //

  .icon-wrapper {
    background-color: color(marriott-light-grey-2);

    .icon {
      display: flex;
      align-items: center;
      height: 100%;

      color: color(app-white);
      font-size: 18px;

      transform: translateY(1px);
    }
  }
}

.section-subheader {
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  padding: 0 30px;

  background-color: color(app-white);
  border-bottom: 1px solid color(marriott-light-grey-2);
  border-top-left-radius: $main-border-radius;
  border-top-right-radius: $main-border-radius;
  box-shadow: $main-box-shadow;

  .left-part {
    height: 100%;
    margin-right: 15px;

    .options {
      display: flex;
      height: 100%;

      .option {
        display: flex;
        flex-direction: column;

        padding: 15px 30px 15px 0;

        border-right: 1px solid color(marriott-light-grey-2);

        &:not(:last-child) {
          margin-right: 30px;
        }

        .title-wrapper {
          display: flex;
          align-items: center;
          height: 100%;

          .checkbox-title {
            margin-right: 15px;

            color: color(marriott-light-grey);
            font-weight: 700;
            font-size: 14px;
            white-space: nowrap;

            transition: color 0.2s ease-in-out;

            &.active {
              color: color(marriott-dark-grey);
            }
          }
        }

        .partially-mandatory {
          margin-top: 6px;

          color: color(marriott-light-grey);
          font-size: 12px;
          font-style: italic;
        }
      }
    }
  }

  .right-part {
    padding: 15px 0;

    .delete-button {
      //
    }
  }

  .variants-dropdown {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
}
