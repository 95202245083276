@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

$main-color: color(marriott-primary);

.hidden-title {
  $height: 20px;

  position: sticky;
  top: $subheader-height + $admin-breadcrumbs-height + ($height / 2);
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: $height;
  margin-bottom: -$height;
  padding-right: 145px;
  padding-left: 220px;

  color: $main-color;
  font-weight: 700;
  font-size: 12px;

  pointer-events: none;
}

.variant {
  position: relative;

  .anchor {
    $top: $subheader-height + $admin-breadcrumbs-height + 70px;

    position: absolute;
    top: -$top;

    visibility: hidden;
  }
  .fly-sidebar {
    position: absolute;
    top: 0;
    right: calc(100% + 50px);
    z-index: 1;
    background-color: #ffffff;
    width: 350px;
    height: calc(100% + 120px);
    padding: 20px 0;
    border-right: 1px solid var(--marriott-light-grey-2);
    &.hide {
      display: none;
    }

    @media (max-width: 1200px) {
      right: calc(100% + 20px);
      width: 300px;
    }

    .sticky-element {
      position: sticky;
      top: $subheader-height + $admin-breadcrumbs-height  + 20px;

      width: 100%;
      max-height: 100%;

      @include custom-scrollbar($thumb-color: transparent, $track-color: rgba(249, 249, 249, 1));

      &:hover {
        @include custom-scrollbar($thumb-color: lightgrey, $track-color: rgba(249, 249, 249, 1));
      }

      .sticky-element-content {
        margin-right: 25px;
        margin-left: 25px;
      }
    }
  }

  .variant-wrapper {
    padding: 2px;

    background-color: $main-color;
    border-radius: $main-border-radius;

    .variant-header {
      //
    }

    .variant-body-wrapper {
      //
    }
  }
}
