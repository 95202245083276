@import "@STYLES/colors.scss";

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .title-wrapper {
    display: flex;
    align-items: center;

    .title {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .document-name {
    padding: 0 20px;
    overflow: hidden;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .right-block {
    display: flex;

    .pdf-button-wrapper {
      margin-right: 30px;
      margin-left: auto;
    }

    .close-button-wrapper {
      display: flex;
      align-items: center;
      height: 100%;

      .close-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;

        font-size: 30px;
      }
    }
  }
}
