@import "@STYLES/colors.scss";
@import "@STYLES/mixins.scss";
@import "@STYLES/pdf.scss";

.content {
  $sidebar-width: 330px;
  $sidebar-width-less: 250px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  transform: translateY(-1px);

  @include custom-scrollbar($thumb-color: transparent, $track-color: rgba(249, 249, 249, 1));

  &:hover {
    @include custom-scrollbar($thumb-color: lightgrey, $track-color: rgba(249, 249, 249, 1));
  }

  .info-wrapper {
    position: relative;

    width: $sidebar-width;
    margin-right: 10px;
    padding-right: 25px;
  }

  .item-wrapper {
    $gap: 100px;

    display: flex;
    margin-bottom: $gap / 2;
    padding-top: $gap / 2;

    &:first-child {
      padding-top: $gap / 2 + 34px;
    }

    .info-wrapper::before {
      position: absolute;
      bottom: -$gap / 2;
      left: 0;

      width: 100%;
      height: 1px;

      background-color: color(marriott-light-grey-2);
      transform: translateY(1px);

      content: "";
    }

    .page-wrapper {
      margin: 0 auto;

      .page-placeholder {
        width: $pdf-page-width;
        height: 200px;
        margin: 0 auto;

        border: 1px dashed color(marriott-light-grey);
      }
    }
  }

  .pages {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .section-wrapper {
    display: flex;
  }

  .no-sections {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: $sidebar-width;

    color: color(marriott-light-grey);
    font-size: 14px;
    text-align: center;
  }

  &.hide-info {
    .info-wrapper {
      visibility: hidden;

      pointer-events: none;
    }
  }

  @media (max-width: 1200px) {
    .info-wrapper {
      width: $sidebar-width-less;
    }

    .no-sections {
      padding-left: $sidebar-width-less;
    }
  }
}
