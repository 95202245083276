@import "@STYLES/colors.scss";

.properties {
  //

  .properties-title {
    margin-bottom: 30px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 14px;
  }

  .active-user-checkbox {
    margin-bottom: 20px;
  }

  .expiration-date {
    display: flex;
    align-items: center;

    .title {
      margin-right: 15px;

      font-size: 12px;

      &.disabled {
        color: color(marriott-light-grey);
      }
    }

    .date-picker-wrapper {
      //
    }
  }
}
