@import "@STYLES/colors";

.new-variant-modal {
  width: 440px;
  padding: 25px 35px 35px;

  .title {
    margin-bottom: 25px;

    font-weight: bold;
    font-size: 18px;
  }

  .loading {
    padding: 20px 0;
  }

  .error {
    padding: 20px 0;
    color: color(marriott-orange);
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }
}
