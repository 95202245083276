@import "@STYLES/colors.scss";

.document-sidebar {
    $main-color: color(marriott-primary);
    position: relative;
    width: 100%;
    background-color: color(app-white);
    .documents-title {
        padding: 16px 24px;
        color: #1d2939;
        font-weight: 700;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        border-bottom: 1px solid transparent;
    }
    .documents-list {
        border-bottom: 1px solid var(--marriott-light-grey-2);
    }
 
    .btn-section {
        padding: 10px 24px;
        :global {
            button {
                margin: 0;
            }
        }
    }


    :global {
        .custom-accordion-details,
        .MuiCollapse-wrapperInner {
            padding: 0;
            background: #F9FAFB;
            ul {

                & .MuiButtonBase-root {
                    background: #F9FAFB !important;
                    padding-right: 0;
                }
                & .MuiAccordionDetails-root{
                    padding-right: 0 !important;
                }
            }
        }
    }

    .document-view {
        margin-top: 10px;
    }
}
