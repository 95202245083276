.permission-modal-wrapper {
    display: flex;
    flex-direction: column;
    gap: 18px;
    .permission-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
        }
        button {
            width: 18px;
            height: 18px;
            font-size: 24px;
            &:hover {
                color: #667085;
            }
        }
    }
    .menu-wrapper {
        display: flex;
        align-items: center;
        gap: 14px;
        .brand-search {
            width: 25%;
            z-index: 0;
            :global {
                .input-wapper {
                    border: 1px solid #d9d9d9;
                    border-radius: 6px;
                    align-items: center;
                }
            }
            input {
                height: 38px;
            }
        }
        .active-button {
            background: #f2f4f7;
            color: #667085;
            padding: 5.5px 30px 6px 30px;
            border-radius: 5px;
            text-transform: none;
            border: 1px solid #d0d5dd;
        }
        .active {
            border-color: transparent;
            background: #42b773;
            color: #ffffff;
        }
    }
    :global .tableMainWrapper {
        border-radius: 10px;
        .MuiTableContainer-root {
            border-radius: 10px;
            box-shadow: none;
            .MuiTable-root {
                border: solid 1px #f2f4f7;
                box-shadow: none;
                .MuiTableHead-root {
                    .MuiTableRow-root {
                        .MuiTableCell-head {
                            background: #f9fafb;

                            border-bottom: solid 1px #f2f4f7;
                            &:nth-of-type(2) {
                                border-left: solid 1px #f2f4f7;
                                border-right: solid 1px #f2f4f7;
                            }
                        }
                    }
                }
                .MuiTableBody-root {
                    .MuiTableCell-root {
                        padding: 12px;
                        border-bottom: solid 1px #f2f4f7;
                        &:last-child {
                            border-left: solid 1px #f2f4f7;
                        }
                        .table-cell-wrapper {
                            display: flex;
                            gap: 26.6px;
                            .MuiFormControlLabel-root {
                                .MuiFormControlLabel-label {
                                    color: #344054;
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 14.52px;
                                    text-align: left;
                                }
                                .MuiCheckbox-root {
                                    svg {
                                        path {
                                            color: #f2f4f7;
                                        }
                                    }
                                    &.Mui-checked {
                                        svg {
                                            path {
                                                color: #1890ff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    th {
                        border-right: solid 1px #f2f4f7;
                        padding: 12px;

                        .table-row {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            .user-avatar {
                                width: 32px;
                                min-width: 32px;
                                height: 32px;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 22px;
                                text-align: left;
                                color: #fff;
                            }
                            .user-info {
                                display: flex;
                                flex-direction: column;
                                .user-name {
                                    color: #344054;
                                    font-size: 13px;
                                    font-weight: 400;
                                    line-height: 22px;
                                    text-align: left;
                                }
                                .user-email {
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 20px;
                                    text-align: left;
                                    color: #40a9ff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .footer-wrapper {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        padding: 18px 0 0 0;
        border-top: 1px solid #f0f0f0;
        button {
            padding: 6px 20px;
            text-transform: none;
            border-color: transparent;
            border-radius: 5px;
            font-size: 14px;
            line-height: 22px;
        }
        .cancel-btn {
            background: #e4e7ec;
            color: #667085;
        }
        .save-btn {
            padding: 6px 30px;
            background: #42b773;
            color: #ffffff;
        }
    }
    :global .autocomplete-with-icon {
        &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon {
            .MuiOutlinedInput-root {
                padding-right: 18px;
                gap: 6px;
            }
        }
        .MuiFormControl-root {
            .MuiInputBase-root {
                padding: 7px 11px 7px 8px;
                min-height: 38px;
                svg {
                    // transform: rotate(270deg);
                    fill: #bfbfbf;
                    width: 15px;
                    height: 15px;
                }
                .MuiInputBase-input {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 22px;
                    padding: 0;
                    &::placeholder {
                        color: #bfbfbf;
                        opacity: 1;
                    }
                }
                .MuiOutlinedInput-notchedOutline {
                    border-color: #d9d9d9;
                    border-radius: 6px;
                }
                .MuiAvatar-root {
                    height: 24px;
                    width: 24px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 22px;
                }
            }
        }
    }
}
