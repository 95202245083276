.page {
  position: relative;

  display: flex;

  height: 100vh;

  background-color: white;

  .brand-wrapper {
    width: 50%;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;

    .terms {
      margin-bottom: 20px;
      padding: 0 15px;

      color: #99bcc2;
      font-size: 14px;
      text-align: center;

      a {
        color: inherit;
      }
    }
  }
}
